import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { Link } from 'react-router-dom';
import { offerLocalization } from "../../Localization/offer";
import { LangauageContext } from './../../Contexts/LangauageContext';

export default function CardState({
  title,
  type = "icon",
  icon = null,
  image = null,
  route = null,
  productRoute=null
}) {

  const {lang} = useContext(LangauageContext);


  return (
    <>
      <div className="card pt-3">
        {type === "icon" ? (
          <FontAwesomeIcon
            icon={icon}
            size="5x"
            className="card-img-top text-success"
          />
        ) : (
          <img src={image} className="card-img-top" alt={title}></img>
        )}
        <div className="card-body text-center">
          <h5 className="card-title">{title}</h5>
          <div className="d-flex gap-2 justify-content-center">
          <Link to={route} className="btn btn-danger">
            {offerLocalization[lang].getStart}
          </Link>
          <Link to={productRoute} className="btn btn-dark">
            {offerLocalization[lang].getStartProduct}
          </Link>
          </div>
        </div>
      </div>
    </>
  );
}
