export const notifyLocalization = {
    ar:{
        'loading':'جارى التحميل!',
        'reserved':'سوف يتم اكتمال التحميل فى غضون',
        'milliseconds':'ملي ثانية',
        'successCreate':'تم الانشاء بنجاح',
        'success':'عملية ناجحة',
        'copy':'نسخ الرابط',
        'copySuccess':'تم النسخ بنجاح',
        'position':'top-start',
        'error':'خطأ فى الاتصال',
        'errorMessage':'لا يمكن استرداد معلوماتك الآن.',
    },
    en:{
        'loading':'Loading!',
        'reserved':'I will close in',
        'milliseconds':'milliseconds',
        'successCreate':'Successfully created',
        'success':'success',
        'copy':'copy link',
        'copySuccess':'copied successfully',
        'position':'top-end',
        'error':'Connaction Error',
        'errorMessage':'Your Information can\'t be retrieved right now.',
    }
}