import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./Components/Layout/Layout";
import Account from "./Components/Account/Account";
import Offer from "./Components/Offer/Offer";
import Contact from "./Components/Contact/Contact";
import LangauageContextProvider from "./Contexts/LangauageContext";
import AuthContextProvider from "./Contexts/AuthContext";
import Profile from "./Components/Profile/Profile";
import ChartContextProvider from "./Contexts/ChartContext";
import CampaignDetials from "./Components/Report/CampaignDetials";
import Notfound from "./Components/Error/Notfound";
import RefferalLink from './Components/Offer/RefferalLink/RefferalLink';
import CommissionModel from "./Components/CommissionModel/CommissionModel";
import OfferDetials from './Components/Offer/OfferDetials/OfferDetials';
import Wallet from "./Components/Wallet/Wallet";
import Landing from "./Components/Landing/Landing";
import Login from "./Components/Login/Login";
import Register from "./Components/Register/Register";
import ForgetPassword from './Components/ForgetPassword/ForgetPassword';
import SocialContextProvider from "./Contexts/SocialContext";
import Network from "./Components/Network/Network";
import NetworkContextProvider from "./Contexts/NetworkContext";
import ResetPassword from "./Components/ResetPassword/ResetPassword";
// import TagManager from 'react-gtm-module'

// const tagManagerArgs = {
//   gtmId: 'G-7C3TLS959M'
// }
// TagManager.initialize(tagManagerArgs)

function App() {

  window.dataLayer.push({
    event: 'pageview'
  });
  
  return (
    <>
      <LangauageContextProvider>
        <AuthContextProvider>
          <Routes>
            <Route index element={<SocialContextProvider><Landing /></SocialContextProvider>}></Route>

            <Route path="/login" element={<Login />}></Route>
            <Route path="/register" element={<Register />}></Route>
            <Route path="/reset-password" element={<ForgetPassword />}></Route>
            <Route path="/user/reset-password" element={<ResetPassword />}></Route>
            
            <Route path="/app" element={<Layout />}>
              <Route index element={<ChartContextProvider><Account /></ChartContextProvider>}></Route>
              <Route path="profile" element={<Profile />}></Route>
              <Route path="offer" element={<Offer />}></Route>
              <Route path="offer/:category" element={<RefferalLink />}></Route>
              <Route path="offer/product/:category" element={<OfferDetials />}></Route>
              <Route path="report/campaign/detials" element={<CampaignDetials />}></Route>
              <Route path="support/contact" element={<Contact />}></Route>
              <Route path="support/commission" element={<CommissionModel />}></Route>
              <Route path="wallet" element={<Wallet />}></Route>
              <Route path="affilates/network" element={<NetworkContextProvider><Network /></NetworkContextProvider>}></Route>
            </Route>
            <Route path="*" element={<Notfound />}></Route>
          </Routes>
        </AuthContextProvider>
      </LangauageContextProvider>
    </>
  );
}

export default App;
