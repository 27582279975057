import React , {useContext, useEffect, useState} from "react";
import logo from "./../../Images/logo.webp";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { LangauageContext } from './../../Contexts/LangauageContext';
import LanguageSwitcher from "../Language/LanguageSwitcher";
import { loginLocalization } from "../../Localization/login";
import { instanceOfAxios } from "../../Api/instanceOfAxios";
import Swal from "sweetalert2";
import { validationLocalization } from './../../Localization/validation';
import { AuthContext } from "../../Contexts/AuthContext";
import { navLocalization } from "../../Localization/nav";
import Seo from './../Seo/Seo';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faUser } from "@fortawesome/free-solid-svg-icons";

export default function Register() {

  const {lang} = useContext(LangauageContext);
  const authData = useContext(AuthContext);

  const [affiliate,setAffiliate] = useState(null);
  const [country,setCountry] = useState([]);

  useEffect(()=>{
    if (authData.auth) {
      window.location.replace('/app');
    }

    if (window.location.search) {
      let url = new URLSearchParams(window.location.search);
      setAffiliate(url.get('invitor'));
    }

    instanceOfAxios.get('/countries/all',{headers:{
      "Accept-Language":lang,
      'keyToken':"U3qMG7RsDDoVgzcJjkzOSxbCydTggBqhviOWSl9bZVztwUkDgUT2T7g9y8kMavm1"
    }
  })
    .then(response =>{
      // console.log(response);
      setCountry(response.data);
    }).catch(error=>{
      Swal.fire({
        title:validationLocalization[lang].error,
        // text:errorResponse.message['errors']??errorResponse.data.message,
        icon:"error",
      });
    })
  },[])

  const initValues = {
    fname: "",
    lname: "",
    email: "",
    countries: "",
    mobile:"",
    pass:'',
    rePass:'',
  };

  const formValidation = Yup.object().shape({
    email: Yup.string()
      .email("This filed mut to be valid mail")
      .required("This filed is required"),
      countries: Yup.string()
      .required("This filed is required"),
    fname: Yup.string()
      .required("This filed is required")
      .min(3, "This filed munimum chracter are 3"),
    lname: Yup.string()
      .required("This filed is required")
      .min(3, "This filed munimum chracter are 3"),
    mobile: Yup.string()
      .required("This filed is required")
      .min(7, "This filed munimum chracter are 7"),
      pass:Yup.string()
    .required("This filed is required")
    .min(8, "This filed munimum chracter are 8"),
    rePass: Yup.string()
    .required("This filed is required")
    .min(8, "This filed munimum chracter are 8")
    .oneOf([Yup.ref("pass"), null], "Password must match"),
  });

  const formik = useFormik({
    initialValues: initValues,
    validationSchema: formValidation,
    onSubmit: (values,{resetForm, setSubmitting}) => {

      values['affiliate'] = affiliate;
      
      instanceOfAxios.post('/signup',
        values,
        {headers:{
          "Accept-Language":lang,
          'keyToken':"U3qMG7RsDDoVgzcJjkzOSxbCydTggBqhviOWSl9bZVztwUkDgUT2T7g9y8kMavm1"
        }
      }).then((response)=>{
        
        // let data = response.data.data;
        // set userdata in auth context
        Swal.fire({
          title:validationLocalization[lang].success,
          text:loginLocalization[lang].registeSucess,
          icon:"success",
        }).then((result)=>{
          if (result.isConfirmed) {
            window.location.replace('/login');
          }
        });
        resetForm();
      }).catch((error)=>{
        setSubmitting(false)

        let errorResponse = error.message;
        if (error['response']!== null) {
          if (error.response['data'] !== null) {
            for (const key in error.response.data.data) {
              if (Object.hasOwnProperty.call(error.response.data.data, key)) {
                errorResponse= error.response.data.data[key];
              }
            }
          }
        }
        Swal.fire({
          title:validationLocalization[lang].error,
          text:errorResponse,
          icon:"error",
        });
      });
    },
  });

  return (
    <>
    <Seo title={"Olxforex | Affaliate Regisetr"}></Seo>
    <section className="warpper-color">
      <section className="vh-100">
        <div className="container-fluid h-100">
          <div className="row align-items-center h-100">
            <div className="col-md-6 offset-md-3">
              <section className="bg-secondary bg-opacity-10">
                <div className="rounded-2 p-5">
                  <div className="text-center position-relative">
                    <img
                      src={logo}
                      style={{ width: "300px" }}
                      alt="Olxforex"
                      className="img-fluid"
                    />
                    <div className="position-absolute start-0 top-munis-30">
                      <LanguageSwitcher />
                    </div>
                    <div className="position-absolute end-0 top-munis-30">
                      <Link className="nav-link" to={'/'}>{navLocalization[lang].home}</Link>
                    </div>
                  </div>
                  <div>
                    <form onSubmit={formik.handleSubmit} className="row">
                    <div className="mb-3 col-lg-6">
                        <label
                          htmlFor="exampleInputfname"
                          className="form-label"
                        >
                          {loginLocalization[lang].fname}
                        </label>
                        <input
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.fname}
                          className="form-control"
                          name="fname"
                          id="exampleInputfname"
                        />
                        {formik.errors.fname && formik.touched.fname ? (
                          <div className="text-danger">
                            {formik.errors.fname}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label
                          htmlFor="exampleInputlname"
                          className="form-label"
                        >
                          {loginLocalization[lang].lname}
                        </label>
                        <input
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.lname}
                          className="form-control"
                          name="lname"
                          id="exampleInputlname"
                        />
                        {formik.errors.lname && formik.touched.lname ? (
                          <div className="text-danger">
                            {formik.errors.lname}
                          </div>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputcountries"
                          className="form-label"
                        >
                          {loginLocalization[lang].countries}
                        </label>
                        <select
                          onChange={formik.handleChange}
                          value={formik.values.countries}
                          className="form-select"
                          name="countries"
                          id="exampleInputcountries"
                        >
                          <option>{loginLocalization[lang].selectCountry}</option>
                          {country&&country.map((country,index)=><option key={index}  value={country.name}>{country.name}</option>)}
                        </select>
                        {formik.errors.countries && formik.touched.countries ? (
                          <div className="text-danger">
                            {formik.errors.countries}
                          </div>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputmobile"
                          className="form-label"
                        >
                          {loginLocalization[lang].mobile}
                        </label>
                        <input
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.mobile}
                          className="form-control"
                          name="mobile"
                          id="exampleInputmobile"
                        />
                        {formik.errors.mobile && formik.touched.mobile ? (
                          <div className="text-danger">
                            {formik.errors.mobile}
                          </div>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          {loginLocalization[lang].email}
                        </label>
                        <input
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          className="form-control"
                          name="email"
                          id="exampleInputEmail1"
                        />
                        {formik.errors.email && formik.touched.email ? (
                          <div className="text-danger">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3 col-md-6">
                        <label
                          htmlFor="exampleInputpass1"
                          className="form-label"
                        >
                          {loginLocalization[lang].password}
                        </label>
                        <input
                          type="password"
                          onChange={formik.handleChange}
                          value={formik.values.pass}
                          className="form-control"
                          name="pass"
                          id="exampleInputpass1"
                        />
                        {formik.errors.pass && formik.touched.pass ? (
                          <div className="text-danger">
                            {formik.errors.pass}
                          </div>
                        ) : null}
                      </div>

                      <div className="mb-3 col-md-6">
                        <label
                          htmlFor="exampleInputrePass1"
                          className="form-label"
                        >
                          {loginLocalization[lang].rePassword}
                        </label>
                        <input
                          type="password"
                          onChange={formik.handleChange}
                          value={formik.values.rePass}
                          className="form-control"
                          name="rePass"
                          id="exampleInputrePass1"
                        />
                        {formik.errors.rePass && formik.touched.rePass ? (
                          <div className="text-danger">
                            {formik.errors.rePass}
                          </div>
                        ) : null}
                      </div>
                      
                      <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>
                        {loginLocalization[lang].registe} {formik.isSubmitting&&<FontAwesomeIcon icon={faSpinner} className="fa-spin fa-lg" />}
                      </button>
                    </form>
                    <div className="mt-3">
                      <Link className="text-decoration-none text-dark" to={'/login'}><FontAwesomeIcon icon={faUser} className="me-2 text-danger"/>{loginLocalization[lang].haveAccount}</Link>
                    </div>
                    
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      </section>
    </>
  );
}
