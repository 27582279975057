import React , {useContext, useEffect, useState} from "react";
import logo from "./../../Images/logo.webp";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { LangauageContext } from '../../Contexts/LangauageContext';
import LanguageSwitcher from "../Language/LanguageSwitcher";
import { loginLocalization } from "../../Localization/login";
import { instanceOfAxios } from "../../Api/instanceOfAxios";
import Swal from "sweetalert2";
import { validationLocalization } from '../../Localization/validation';
import { navLocalization } from "../../Localization/nav";
import Seo from '../Seo/Seo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function ResetPassword() {

  const {lang} = useContext(LangauageContext);
  const [hash,setHash] = useState(null);
  const [time,setTime] = useState(null);

  const intitValues = {
    pass:'',
    rePass:''
  }

  const validation = Yup.object().shape({
    pass:Yup.string()
    .required("This filed is required")
    .min(8, "This filed munimum chracter are 8"),
    rePass: Yup.string()
    .required("This filed is required")
    .min(8, "This filed munimum chracter are 8")
    .oneOf([Yup.ref("pass"), null], "Password must match"),
  });

  useEffect(()=>{
    if (window.location.search) {
      let url = new URLSearchParams(window.location.search);
      setHash(url.get('hash'));
      setTime(url.get('date'));
    }
  })

  const formik =  useFormik({
    initialValues: intitValues,
    validationSchema: validation,
    onSubmit: (values,{resetForm,setSubmitting}) => {

      instanceOfAxios.post('/update-reset-password',
        {
          "password":values.pass,
          "hash":hash,
          "time":time
        },
        {headers:{
          "Accept-Language":lang,
          'keyToken':"U3qMG7RsDDoVgzcJjkzOSxbCydTggBqhviOWSl9bZVztwUkDgUT2T7g9y8kMavm1"
        }
      }).then((response)=>{
        // jquery('#staticBackdrop').modal('hide');
        Swal.fire({
          title:validationLocalization[lang].success,
          icon:"success",
        });
        resetForm();
        setTimeout(() => {
          window.location.replace('/login');
        }, 1000);
      }).catch((error)=>{
        setSubmitting(false)

        let errorResponse = error.message;
        if (error['response']!== null) {
          if (error.response['data'] !== null) {
            for (const key in error.response.data.data) {
              if (Object.hasOwnProperty.call(error.response.data.data, key)) {
                errorResponse= error.response.data.data[key];
              }
            }
          }
        }
        Swal.fire({
          title:validationLocalization[lang].error,
          text:errorResponse,
          icon:"error",
        });
      });      
    }
  })

  return (
    <>
    <Seo title={"Olxforex | Affaliate reset Password"}></Seo>
    <section className="warpper-color">
      <section className="vh-100">
        <div className="container-fluid h-100">
          <div className="row align-items-center h-100">
            <div className="col-md-6 offset-md-3">
              <section className="bg-secondary bg-opacity-10">
                <div className="rounded-2 p-5">
                  <div className="text-center position-relative">
                    <img
                      src={logo}
                      style={{ width: "300px" }}
                      alt="Olxforex"
                      className="img-fluid"
                    />
                    <div className="position-absolute start-0 top-munis-30">
                      <LanguageSwitcher />
                    </div>
                    <div className="position-absolute end-0 top-munis-30">
                      <Link className="nav-link" to={'/'}>{navLocalization[lang].home}</Link>
                    </div>
                  </div>
                  <div>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputpass1"
                          className="form-label"
                        >
                          {loginLocalization[lang].password}
                        </label>
                        <input
                          type="password"
                          onChange={formik.handleChange}
                          value={formik.values.pass}
                          className="form-control"
                          name="pass"
                          id="exampleInputpass1"
                        />
                        {formik.errors.pass && formik.touched.pass ? (
                          <div className="text-danger">
                            {formik.errors.pass}
                          </div>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputrePass1"
                          className="form-label"
                        >
                          {loginLocalization[lang].rePassword}
                        </label>
                        <input
                          type="password"
                          onChange={formik.handleChange}
                          value={formik.values.rePass}
                          className="form-control"
                          name="rePass"
                          id="exampleInputrePass1"
                        />
                        {formik.errors.rePass && formik.touched.rePass ? (
                          <div className="text-danger">
                            {formik.errors.rePass}
                          </div>
                        ) : null}
                      </div>
                     
                      <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>
                        {loginLocalization[lang].resetPass} {formik.isSubmitting&&<FontAwesomeIcon icon={faSpinner} className="fa-spin fa-lg" />}
                      </button>
                    </form>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      </section>
    </>
  );
}
