import React, { useContext } from "react";
import { navLocalization } from "../../../Localization/nav";
import { LangauageContext } from "../../../Contexts/LangauageContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../../Contexts/AuthContext";
import Swal from "sweetalert2";
import { validationLocalization } from "../../../Localization/validation";
import { instanceOfAxios } from "./../../../Api/instanceOfAxios";
// import * as jquery from 'jquery';
import { modalLocalization } from "./../../../Localization/modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { loginLocalization } from "../../../Localization/login";
// const Swal = React.lazy(()=>import('sweetalert2'));
const jquery = React.lazy(() => import("jquery"));

export default function ResetPassword() {
  const { lang } = useContext(LangauageContext);
  const { auth } = useContext(AuthContext);
  // const navigate = useNavigate();

  const intitValues = {
    pass: "",
    rePass: "",
  };

  const validation = Yup.object().shape({
    pass: Yup.string()
      .required("This filed is required")
      .min(8, "This filed munimum chracter are 8"),
    rePass: Yup.string()
      .required("This filed is required")
      .min(8, "This filed munimum chracter are 8")
      .oneOf([Yup.ref("pass"), null], "Password must match"),
  });

  const formik = useFormik({
    initialValues: intitValues,
    validationSchema: validation,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      // console.log(values);

      instanceOfAxios
        .post(
          "/change-password",
          {
            password: values.pass,
          },
          {
            headers: {
              "Accept-Language": lang,
              Authorization: `Bearer ${auth.token}`,
            },
          }
        )
        .then((response) => {
          // jquery('#staticBackdrop').modal('hide');
          jquery(".modal").hide();
          jquery(".modal").removeClass("show");
          jquery(".modal").removeAttr("aria-modal");
          jquery(".modal").removeAttr("role");
          jquery(".modal").attr("aria-hidden", "true");
          jquery(".modal-backdrop").remove();
          Swal.fire({
            title: validationLocalization[lang].success,
            icon: "success",
          });
          resetForm();
          // sessionStorage.removeItem('user');
          // authCreditional(null);
          // redirect to login page
          // navigate('/');
        })
        .catch((error) => {
          setSubmitting(false);

          let errorResponse = error.message;
          if (error["response"] !== null) {
            if (error.response["data"] !== null) {
              for (const key in error.response.data.data) {
                if (Object.hasOwnProperty.call(error.response.data.data, key)) {
                  errorResponse = error.response.data.data[key];
                }
              }
            }
          }
          // console.log(error);
          Swal.fire({
            title: validationLocalization[lang].error,
            text: errorResponse,
            icon: "error",
          });
          // console.log(errorResponse);
        });
    },
  });

  return (
    <>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                {navLocalization[lang].changePassowrd}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={formik.handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="pass" className="form-label">
                    {" "}
                    {loginLocalization[lang].password}
                  </label>
                  <input
                    type="password"
                    value={formik.values.pass}
                    onChange={formik.handleChange}
                    className="form-control"
                    name="pass"
                    id="pass"
                  />
                </div>
                {formik.errors.pass && formik.touched.pass ? (
                  <div className="text-danger">{formik.errors.pass}</div>
                ) : null}

                <div className="mb-3">
                  <label htmlFor="rePass" className="form-label">
                    {" "}
                    {loginLocalization[lang].rePassword}
                  </label>
                  <input
                    type="password"
                    value={formik.values.rePass}
                    onChange={formik.handleChange}
                    className="form-control"
                    name="rePass"
                    id="rePass"
                  />
                </div>
                {formik.errors.rePass && formik.touched.rePass ? (
                  <div className="text-danger">{formik.errors.rePass}</div>
                ) : null}

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    {modalLocalization[lang].close}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={formik.isSubmitting}
                  >
                    {modalLocalization[lang].changePassword}{" "}
                    {formik.isSubmitting && (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        className="fa-spin fa-lg"
                      />
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
