
export const navLocalization = {
    ar:{
        'home':'الصفحة الرئيسية',
        'profile':'الحساب',
        'signOut':'تسجيل خروج',
        'app':'لوحة التحكم',
        'adverties':'الاعلانات',
        'offer':'روابط الإحالة',
        'contact':'تواصل معانا',
        'support':'الدعم',
        'report':'التقارير',
        'commissionModel':'نموذج العمولة',
        'downloads':'تحميل',
        'invoices':'الفواتير',
        'conversions':'تحويلات الارصدة',
        'courses':'الإكاديمية',
        'indicator':'الموشرات',
        'expert':'الأكسبرتات',
        'books':'الكتب',
        'changePassowrd':'تغير الرقم السري',
        'wallet':'المحفظة',
        'walletWithdraw':'السحب',
        'invite':'شبكة المعلنين',
    },
    en:{
        'home':'Home',
        'profile':'Profile',
        'signOut':'Sign out',
        'app':'Dashboard',
        'adverties':'Adverties',
        'offer':'Referral links',
        'contact':'Contact us',
        'support':'Support',
        'report':'Reports',
        'commissionModel':'Commission Model',
        'downloads':'Downloads',
        'invoices':'Invoices',
        'conversions':'Campaign Detials',
        'courses':'Courses',
        'indicator':'Indicators',
        'expert':'Expert',
        'books':'Books',
        'changePassowrd':'Change Passowrd',
        'wallet':'Wallet',
        'walletWithdraw':'Withdraw',
        'invite':'affilate network',
    }
}