export const reportLocalization = {
    ar:{
        'get':'استرجاع البيانات',
        'empty':'لايوجد بيانات للاسترجاع',
        'start':'تاريخ البداية',
        'end':'تاريخ النهاية',
    },
    en:{
        'get':'Get Data',
        'empty':'There are no date',
        'start':'Start date',
        'end':'End date',
    }
}