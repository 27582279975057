import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar/Sidebar";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";

export default function Layout() {
  return (
    <>
      <Navbar></Navbar>
      <div className="container-fluid">
        <div className="row g-0">
          <div className="col-md-4 col-lg-2">
            <Sidebar></Sidebar>
          </div>
          <div className="col-md-8 col-lg-10">
            <div className="warpper-color p-2">
              <Outlet />
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
