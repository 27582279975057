import React, { useContext } from 'react'
import { widgetLocalization } from './../../Localization/widget';
import { LangauageContext } from '../../Contexts/LangauageContext';

export default function ReportTable({dataSet}) {
  const { lang } = useContext(LangauageContext);

  return <>
  <div className="table-responsive">
          <table className="table table-light table-striped">
            <thead>
              <tr>
                <th>{widgetLocalization[lang].product}</th>
                <th>{widgetLocalization[lang].clicks}</th>
                <th>{widgetLocalization[lang].reached}</th>
                <th>{widgetLocalization[lang].commissions}</th>
              </tr>
            </thead>

            <tbody>
              {dataSet? dataSet.map((data,index)=> (<tr key={index}>
                <td>{(data.product)}</td>
                <td>{data.clicks}</td>
                <td>{data.buy}</td>
                <td>{data.commissions} USD</td>
              </tr>)) : (<tr>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0 USD</td>
              </tr>)}
            </tbody>
          </table>
        </div>
  </>
}
