import React from 'react'
import { Hourglass } from 'react-loader-spinner'

export default function Lodar() {
  return <>
  <section className='vh-100 d-flex p-5 justify-content-center'>
  <Hourglass
  visible={true}
  height="50"
  width="50"
  ariaLabel="hourglass-loading"
  wrapperStyle={{}}
  wrapperClass=""
  colors={['#3cb449', '#ee1f23']}
  />
  </section>
  </>
}
