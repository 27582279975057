import React, { useContext } from 'react'
import { LangauageContext } from '../../Contexts/LangauageContext';
import { refferalLocalization } from '../../Localization/refferal';

export default function BannerView({ imageLink, imageFile, downloadBtn, copyBtn }) {
    const { lang } = useContext(LangauageContext);

    return (
        <div className='col-md-4'>

            <div className='mb-3'>
                <div style={{ height: '400px' }}>
                    <h2 className="fs-6">{refferalLocalization[lang].preview}</h2>
                    <div className="card h-100 bg-image-banner" style={{ backgroundImage: `url(${imageLink})` }}>
                        {/* <img src={imageLink} className="img-fluid img-thumbnail h-100" alt="addvertinig" /> */}
                    </div>
                </div>
                <div className='my-5 d-flex gap-3 justify-content-center'>
                    <button onClick={() => { downloadBtn(imageLink, imageFile) }} className='btn btn-primary'>{refferalLocalization[lang].download}</button>
                    <button className='btn btn-primary' title={refferalLocalization[lang].promotCode} onClick={() => { copyBtn(imageLink) }}>{refferalLocalization[lang].copy}</button>
                </div>
            </div>
        </div>
    )
}
