import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../Contexts/AuthContext';
import { navLocalization } from '../../Localization/nav';
import BreadCrumb from '../Widget/BreadCrumb';
import { LangauageContext } from '../../Contexts/LangauageContext';
import { faBank, faWallet } from '@fortawesome/free-solid-svg-icons';
import WalletState from '../Widget/WalletState';
import { instanceOfAxios } from '../../Api/instanceOfAxios';
import { widgetLocalization } from '../../Localization/widget';
import { accountLocalization } from './../../Localization/account';
import PaginateItems from '../Widget/PaginateItems';
import Lodar from './../Loading/Lodar';
import WalletProccess from '../Widget/WalletProccess';

export default function Wallet() {

  const { auth } = useContext(AuthContext);
  const { lang } = useContext(LangauageContext);
  const [balance, setBalance] = useState(JSON.parse(sessionStorage.getItem('user')).approved_balance);
  const [transaction, setTransaction] = useState([]);

  const handelBlance = () => {
    instanceOfAxios.get('/wallet/balance', {
      headers: {
        "Accept-Language":lang,
        Authorization: `Bearer ${auth?.token}`
      }
    }).then((response) => {
      setBalance(response.data.data.balance);
    }).catch((error) => {
      console.log(error);
    })
  }

  const handelTransactions = () => {
    instanceOfAxios.get('/wallet/transactions', {
      headers: {
        "Accept-Language":lang,
        Authorization: `Bearer ${auth?.token}`
      }
    }).then((response) => {
      setTransaction(response.data.data);
    }).catch((error) => {
      console.log(error);
    })
  }

  useEffect(() => {
    if (auth === null) {
      window.location.replace('/');
    }
    handelBlance();
  },[]);

  useEffect(()=>{
    handelTransactions();
  },[balance])

  return <>
    <BreadCrumb current={navLocalization[lang].wallet} previous={navLocalization[lang].app} />
    <section className='mb-5'>
      <div className="container-fluid">
        <h1>{accountLocalization[lang].walletOverview}</h1>
        <div className='mb-3'>
          <div className="row">
            <div className="col-lg-6">
              <WalletState
                title={widgetLocalization[lang].approvedCommission}
                balance={balance}
                icon={faWallet}
                canUpdate={true}
                updateHandler={handelBlance}
              />
            </div>
            <div className="col-lg-6">
              <WalletProccess
                title={widgetLocalization[lang].approvedCommission}
                icon={faBank}
                payPal={true}
                usdt={true}
              />
            </div>
          </div>
        </div>
        <div className="mb-3">
        <h2>{accountLocalization[lang].transactionOverview}</h2>
        <div className="table-responsive mb-5">
          {transaction.length > 0 ? <PaginateItems itemsPerPage={10} dataSet={transaction} />:<Lodar />}
        </div>
        </div>
      </div>
    </section>
  </>
}
