import React, { useContext, useEffect } from "react";
import BreadCrumb from "../Widget/BreadCrumb";
import CardState from "../Widget/CardState";
import {
  faBook,
  faGraduationCap,
  faLaptopCode,
  faStore,
} from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../Contexts/AuthContext";
import { LangauageContext } from "../../Contexts/LangauageContext";
import { navLocalization } from "../../Localization/nav";

export default function Offer() {
  const {auth} = useContext(AuthContext);
  const {lang} = useContext(LangauageContext);

  useEffect(()=>{
    if (auth === null) {
      window.location.replace('/');
    }
  });

  return (
    <>
      <BreadCrumb current={navLocalization[lang].offer} previous={navLocalization[lang].adverties} />
      <div className="my-5">
        <div className="container-fluid">
          <div className="row gy-3">
            <div className="col-md-6">
              <CardState title={navLocalization[lang].courses} route={'courses'} productRoute={'product/courses'} icon={faGraduationCap} />
            </div>

            <div className="col-md-6">
              <CardState title={navLocalization[lang].indicator} route={'indicator'} productRoute={'product/indicator'} icon={faStore} />
            </div>

            <div className="col-md-6">
              <CardState title={navLocalization[lang].expert} route={'expert'} productRoute={'product/expert'} icon={faLaptopCode} />
            </div>

            <div className="col-md-6">
              <CardState title={navLocalization[lang].books} route={'books'} productRoute={'product/books'} icon={faBook} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
