import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import logo from "../../Images/logo.webp";

export default function HomeLoading() {
  return <>
  <section className='vh-100 container'>
    <div className='h-100 d-flex align-items-center justify-content-center'>
    <div className='text-center'>
    <div className='mb-3'>
        <img src={logo} className='img-fluid navbar-logo' loading='lazy'/>
    </div>
    <div className='mb-3'>
    <FontAwesomeIcon icon={faSpinner} className='fa-spin fa-3x'></FontAwesomeIcon>
    </div>
    <h1>Your website is Loading</h1>
    <p>We just make sure that you are not a robot, please wait a few moments.</p>
    </div>
    </div>
  </section>
  </>
}
