import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import payPalImage from "../../Images/payment/Paypal_2014_logo.webp";
import usdtImage from "../../Images/payment/imageedit_5_8438855739.webp";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { instanceOfAxios } from "./../../Api/instanceOfAxios";
import Swal from "sweetalert2";
import { AuthContext } from "../../Contexts/AuthContext";
import { LangauageContext } from "../../Contexts/LangauageContext";
import { validationLocalization } from "../../Localization/validation";
import { modalLocalization } from './../../Localization/modal';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function WalletProccess({
  title,
  icon,
  payPal = true,
  usdt = false,
  active = true,
}) {
  const { lang } = useContext(LangauageContext);
  const { auth } = useContext(AuthContext);

  const [show, setShow] = useState(false);
  const [mail, setMail] = useState(false);
  const [address, setAddress] = useState(false);

  const initvalues = {
    data: "",
    amount: 0.0,
  };

  const validationForm = Yup.object().shape({
    data: Yup.string()
      .required("This filed is required")
      .min(8, "This filed munimum chracter are 8"),
    amount: Yup.number()
      .required("This filed is required")
      .min(50, "Minimum withdraw is 50 Dollar")
      .max(
        Number(JSON.parse(sessionStorage.getItem("user")).approved_balance),
        "Your amount greater than balance"
      )
      .test("is-decimal", "invalid decimal", (value) =>
        (value + "").match(/^\d*\.{0,1}\d*$/)
      ),
  });

  const formik = useFormik({
    initialValues: initvalues,
    validationSchema: validationForm,
    onSubmit: (values,{resetForm, setSubmitting}) => {
      let selectType = mail ? "paypal" : "usdt";

      let data = {};

      if (mail) {
        data = { type: selectType, amount: values.amount, email: values.data };
      } else {
        data = {
          type: selectType,
          amount: values.amount,
          address: values.data,
        };
      }

      instanceOfAxios
        .post("/wallet/withdraw", data, {
          headers: {
          "Accept-Language":lang,
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((response) => {
          Swal.fire({
            title: validationLocalization[lang].success,
            icon: "success",
          });
          setShow(false);
          resetForm();
        })
        .catch((error) => {
        setSubmitting(false)

        let errorResponse = error.message;
        if (error['response']!== null) {
          if (error.response['data'] !== null) {
            for (const key in error.response.data.data) {
              if (Object.hasOwnProperty.call(error.response.data.data, key)) {
                errorResponse= error.response.data.data[key];
              }
            }
          }
        }

          Swal.fire({
            title: validationLocalization[lang].error,
            text: errorResponse,
            icon: "error",
          });
        });
    },
  });

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    let element = e.target.parentNode;
    let target = element.getAttribute("dataType");
    if (target === "usdt") {
      setAddress(true);
      setMail(false);
    } else {
      setAddress(false);
      setMail(true);
    }
    setShow(true);
  };

  return (
    <>
      <div className="my-3">
        <small className="fw-bold">{title}</small>
        <div className="bg-light-subtle mt-3 shadow-sm d-flex p-3">
          <FontAwesomeIcon
            icon={icon}
            size="3x"
            className={active ? "text-success" : "text-secondary"}
          />
          <div className="mx-2">
            {payPal && (
              <Button
                datatype="payPal"
                variant="bg-transparent border-0"
                onClick={handleShow}
              >
                <img src={payPalImage} alt="payPal" width={45} />
              </Button>
            )}

            {usdt && (
              <Button
                datatype="usdt"
                variant="bg-transparent border-0"
                onClick={handleShow}
              >
                <img src={usdtImage} alt="usdt" width={100} />{" "}
                <sub className="fw-bold text-secondary">TRC20</sub>
              </Button>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalLocalization[lang].withdraw}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              <label htmlFor="data" className="form-label">
                
                {address ? modalLocalization[lang].address : modalLocalization[lang].paypal}
              </label>
              <input
                type="text"
                value={formik.values.data}
                onChange={formik.handleChange}
                className="form-control"
                name="data"
                id="data"
              />
            </div>
            {formik.errors.data && formik.touched.data ? (
              <div className="text-danger">{formik.errors.data}</div>
            ) : null}

            <div className="mb-3">
              <label htmlFor="amount" className="form-label">
                {modalLocalization[lang].withdrawAmount}
              </label>
              <input
                type="text"
                value={formik.values.amount}
                onChange={formik.handleChange}
                className="form-control"
                name="amount"
                id="amount"
              />
            </div>

            <div>
              <small className="text-danger">{modalLocalization[lang].walletAmountHint}</small>
            </div>
            
            {formik.errors.amount && formik.touched.amount ? (
              <div className="text-danger">{formik.errors.amount}</div>
            ) : null}

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
              {modalLocalization[lang].close}

              </Button>
              <Button type="submit" variant="primary" disabled={formik.isSubmitting}>
              {modalLocalization[lang].sendRequest} {formik.isSubmitting&&<FontAwesomeIcon icon={faSpinner} className="fa-spin fa-lg" />}
              
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
