
export const modalLocalization = {
    ar:{
        'close':'اغلاق',
        'changePassword':'تغير الرقم السرى',
        'sendRequest':'ارسال الطلب',
        'withdraw':'سحب الاموال',
        'invite':'ارسال الدعوة',
        'withdrawAmount':'مبلغ السحب',
        'address':"عنوان المحفظة",
        'paypal':"البريد الالكترونى لحساب الباي بال",
        'walletAmountHint':'الحد الادنى للسحب هو 50 دولار',
    },
    en:{
        'close':'Close',
        'changePassword':'Change password',
        'sendRequest':'Send Request',
        'withdraw':'Withdraw Money',
        'invite':'Send Invitation',
        'withdrawAmount':'Withdraw amount',
        'address':"Wallet address",
        'paypal':"Payapl account email",
        'walletAmountHint':'The minimum amount of withdraw is 50$',

    }
}