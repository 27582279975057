export const notFoundLocalization = {
    ar:{
        'title':'تبدو وكأنك ضائع',
        'subTitle':'الصفحة التي تبحث عنها غير متوفرة!',
        'button':'اذهب إلى الصفحة الرئيسية',
    },
    en:{
        'title':'Look like you\'re lost',
        'subTitle':'the page you are looking for is not available!',
        'button':'Go to Home',
    }
}