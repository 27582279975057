import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useContext, useEffect } from "react";
import BreadCrumb from "../Widget/BreadCrumb";
import { navLocalization } from "../../Localization/nav";
import { LangauageContext } from "../../Contexts/LangauageContext";
import { contactLocalization } from "../../Localization/contact";
import { AuthContext } from "../../Contexts/AuthContext";
import { instanceOfAxios } from './../../Api/instanceOfAxios';

import Swal from "sweetalert2";
import { validationLocalization } from "../../Localization/validation";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Contact() {
  const {lang} = useContext(LangauageContext);
  const { auth } = useContext(AuthContext);

  useEffect(()=>{
    if (auth === null) {
        window.location.replace('/')
    }
  },[]);

  const initValues = {
    options: "",
    message: "",
  };

  const formValidation = Yup.object().shape({
    options: Yup.string().required("This filed is required"),
    message: Yup.string()
      .required("This filed is required")
      .min(8, "This filed munimum chracter are 8"),
  });

  const formik = useFormik({
    initialValues: initValues,
    validationSchema: formValidation,
    onSubmit: (values,{resetForm, setSubmitting}) => {
      instanceOfAxios.post('/contact/store',{
        'type':values.options,
        'message':values.message
      },{
        headers:{
          "Accept-Language":lang,
          Authorization:`Bearer ${auth.token}`
        }
      }).then((response)=>{
        Swal.fire({
          title:validationLocalization[lang].success,
          icon:"success",
        });

        resetForm();

      }).catch((error)=>{
        setSubmitting(false)
        let errorResponse = error.message;
        if (error['response']!== null) {
          if (error.response['data'] !== null) {
            for (const key in error.response.data.data) {
              if (Object.hasOwnProperty.call(error.response.data.data, key)) {
                errorResponse= error.response.data.data[key];
              }
            }
          }
        }
        Swal.fire({
          title:validationLocalization[lang].error,
          text:errorResponse,
          icon:"error",
        });
      })
    },
  });

  return (
    <>
      <BreadCrumb current={navLocalization[lang].contact} previous={navLocalization[lang].support} />

      <section className="bg-light-subtle my-5">
        <div className="rounded-2 p-5">
          <div mb-3>
            <h2>{navLocalization[lang].contact}</h2>
            <p className="text-dark text-opacity-50">
              {contactLocalization[lang].slug}
            </p>
          </div>
          <div>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <label htmlFor="options" className="form-label">
                  {contactLocalization[lang].subject}
                </label>
                <select
                  name="options"
                  className="form-select"
                  onChange={formik.handleChange}
                  value={formik.values.options}
                  id="options"
                >
                  <option value="">{contactLocalization[lang].selectSubject}</option>
                  <option value="How to use Olxforex Affiliate">
                  {contactLocalization[lang].option1}
                  </option>
                  <option value="How to add payout details">
                  {contactLocalization[lang].option2}
                  </option>
                  <option value="I don't have a tax ID">
                  {contactLocalization[lang].option3}
                  </option>
                  <option value="I have a missing payment">
                  {contactLocalization[lang].option4}
                  </option>
                  <option value="Change or Reset Password">
                  {contactLocalization[lang].option5}
                  </option>
                  <option value="I have untracked orders">
                  {contactLocalization[lang].option6}
                  </option>
                  <option value="My affiliate link is not trackable">
                  {contactLocalization[lang].option7}
                  </option>
                  <option value="Others">{contactLocalization[lang].option8}</option>
                </select>
                {formik.errors.options && formik.touched.options ? (
                  <div className="text-danger">{formik.errors.options}</div>
                ) : null}
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  {contactLocalization[lang].message}
                </label>
                <textarea
                  className="form-control"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  id="message"
                  rows="3"
                ></textarea>
                {formik.errors.message && formik.touched.message ? (
                  <div className="text-danger">{formik.errors.message}</div>
                ) : null}
              </div>
              <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>
                {contactLocalization[lang].submit} {formik.isSubmitting&&<FontAwesomeIcon icon={faSpinner} className="fa-spin fa-lg" />}
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
