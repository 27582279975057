export const footerLocalization = {
    ar:{
        'all':'جميع الحقوق',
        'reserved':'محفوظه لـ',
        'follow':'وسائل تواصل الاجتماعى'

    },
    en:{
        'all':'All',
        'reserved':'are reserved',
        'follow':'Follow Us'
    }
}