import React, { useContext, useEffect } from 'react'
import BreadCrumb from './../Widget/BreadCrumb';
import { LangauageContext } from '../../Contexts/LangauageContext';
import { AuthContext } from '../../Contexts/AuthContext';
import { navLocalization } from '../../Localization/nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfinity } from '@fortawesome/free-solid-svg-icons';
import { commissionModelLocalization } from '../../Localization/commissionModel';

export default function CommissionModel() {

    const { lang } = useContext(LangauageContext);
    const {auth} = useContext(AuthContext);

    useEffect(()=>{
        if (auth === null) {
        window.location.replace('/');
        }
    });

  return <>
    <BreadCrumb current={navLocalization[lang].commissionModel} previous={navLocalization[lang].support} />


  <section className='my-3'>
    <div className="container-fluid">
        <div className="row">
            <div className='mb-3'>
                <h2>{commissionModelLocalization[lang].heading}</h2>
                <p>
                {commissionModelLocalization[lang].subHeading}
                </p>
            </div>

            <div className='mb-3 table-responsive'>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                        <th scope='col'>#</th>
                        <th scope='col'>{commissionModelLocalization[lang].type}</th>
                        <th scope='col'>{commissionModelLocalization[lang].from}</th>
                        <th scope='col'>{commissionModelLocalization[lang].to}</th>
                        <th scope='col'>{commissionModelLocalization[lang].commission}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope='row'>1</th>
                            <td>{commissionModelLocalization[lang].stander}</td>
                            <td>1</td>
                            <td>10</td>
                            <td>5%</td>
                        </tr>

                        <tr>
                            <th scope='row'>2</th>
                            <td>{commissionModelLocalization[lang].silver}</td>
                            <td>10</td>
                            <td>20</td>
                            <td>6%</td>
                        </tr>

                        <tr>
                            <th scope='row'>3</th>
                            <td>{commissionModelLocalization[lang].gold}</td>
                            <td>21</td>
                            <td>30</td>
                            <td>7%</td>
                        </tr>

                        <tr>
                            <th scope='row'>3</th>
                            <td>{commissionModelLocalization[lang].diamond}</td>
                            <td>31</td>
                            <td>40</td>
                            <td>8%</td>
                        </tr>

                        <tr>
                            <th scope='row'>4</th>
                            <td>{commissionModelLocalization[lang].elit}</td>
                            <td>41</td>
                            <td>50</td>
                            <td>9%</td>
                        </tr>

                        <tr>
                            <th scope='row'>5</th>
                            <td>{commissionModelLocalization[lang].elitPlus}</td>
                            <td>51</td>
                            <td><FontAwesomeIcon icon={faInfinity}></FontAwesomeIcon></td>
                            <td>10%</td>
                        </tr>


                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </section>
  </>
}
