import React, { useContext } from "react";
import { widgetLocalization } from "../../Localization/widget";
import { LangauageContext } from "../../Contexts/LangauageContext";

export default function AccountState() {
    const {lang} = useContext(LangauageContext);
  return (
    <div class="alert alert-warning" role="alert">
      {widgetLocalization[lang].AccountReview}
    </div>
  );
}
