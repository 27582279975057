import React, { createContext, useEffect, useState } from "react";


export const LangauageContext = createContext();

const LangauageContextProvider = (props)=>{

    const [lang, setLang] = useState(localStorage.getItem('affiliateLang')??'en');

    useEffect(()=>{
        let html = document.querySelector('html');
        html.lang = lang ;
        html.dir = lang ==='en'? 'ltr':'rtl';
    },[lang]);
    
    const changeLang = ()=>{
        
        if (lang === 'en') {
            localStorage.setItem('affiliateLang','ar');
            setLang('ar');
        } else {
            localStorage.setItem('affiliateLang','en');
            setLang('en');
        }
    }

    return <LangauageContext.Provider value={{lang,changeLang}}>
        {props.children}
    </LangauageContext.Provider>;
}

export default LangauageContextProvider;