
export const validationLocalization = {
    ar:{
        'error':'خطأ',
        'success':'عملية صحيحه'
    },
    en:{
        'error':'Error',
        'success':'Success'
    }
}