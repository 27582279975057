import React, { createContext, useState } from 'react'

export const AuthContext = createContext();

export default function AuthContextProvider(props) {
  const [auth,setAuth] =useState(JSON.parse(sessionStorage.getItem('user')));

  const authCreditional = (authObj)=>{
    sessionStorage.setItem('user',JSON.stringify(authObj));
    setAuth(authObj);
  }

  return <AuthContext.Provider value={{auth,authCreditional}}>
    {props.children}
  </AuthContext.Provider> 
}
