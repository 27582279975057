import React , {useContext, useEffect} from "react";
import logo from "./../../Images/logo.webp";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { LangauageContext } from '../../Contexts/LangauageContext';
import LanguageSwitcher from "../Language/LanguageSwitcher";
import { loginLocalization } from "../../Localization/login";
import { instanceOfAxios } from "../../Api/instanceOfAxios";
import { validationLocalization } from '../../Localization/validation';
import { AuthContext } from "../../Contexts/AuthContext";
import { navLocalization } from "../../Localization/nav";
import Seo from '../Seo/Seo';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faSpinner, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";


export default function Login() {

  const {lang} = useContext(LangauageContext);
  const authData = useContext(AuthContext);

  useEffect(()=>{
    if (authData.auth) {
      window.location.replace('/app');
    }
  })

  const initValues = {
    email: "",
    password: "",
  };

  const formValidation = Yup.object().shape({
    email: Yup.string()
      .email("This filed mut to be valid mail")
      .required("This filed is required"),
    password: Yup.string()
      .required("This filed is required")
      .min(8, "This filed munimum chracter are 8"),
  });

  const formik = useFormik({
    initialValues: initValues,
    validationSchema: formValidation,
    onSubmit: (values,{setSubmitting}) => {
      // alert(JSON.stringify(values));
      instanceOfAxios.post('/login',
        values,
        {headers:{
          "Accept-Language":lang,
          'keyToken':"U3qMG7RsDDoVgzcJjkzOSxbCydTggBqhviOWSl9bZVztwUkDgUT2T7g9y8kMavm1"
        }
      }).then((response)=>{
        let data = response.data.data;
        // set userdata in auth context
        authData.authCreditional(data);
        // redirect route to app page
        window.location.replace('/');
      }).catch((error)=>{
        setSubmitting(false)
        let errorResponse = error.response.data;
        Swal.fire({
          title:validationLocalization[lang].error,
          text:errorResponse.message['errors']??errorResponse.data.message,
          icon:"error",
        });
        // console.log(errorResponse);
      });
    },
  });
  return (
    <>
    <Seo title={"Olxforex | Affaliate login"}></Seo>
    <section className="warpper-color">
      <section className="vh-100">
        <div className="container-fluid h-100">
          <div className="row align-items-center h-100">
            <div className="col-md-6 offset-md-3">
              <section className="bg-secondary bg-opacity-10">
                <div className="rounded-2 p-5">
                  <div className="text-center position-relative">
                    <img
                      src={logo}
                      style={{ width: "300px" }}
                      alt="Olxforex"
                      className="img-fluid"
                    />
                    <div className="position-absolute start-0 top-munis-30">
                      <LanguageSwitcher />
                    </div>
                    <div className="position-absolute end-0 top-munis-30">
                      <Link className="nav-link" to={'/'}>{navLocalization[lang].home}</Link>
                    </div>
                  </div>
                  <div>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          {loginLocalization[lang].email}
                        </label>
                        <input
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          className="form-control"
                          name="email"
                          id="exampleInputEmail1"
                        />
                        {formik.errors.email && formik.touched.email ? (
                          <div className="text-danger">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputPassword1"
                          className="form-label"
                        >
                          {loginLocalization[lang].password}
                        </label>
                        <input
                          type="password"
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          className="form-control"
                          name="password"
                          id="exampleInputPassword1"
                        />
                        {formik.errors.password && formik.touched.password ? (
                          <div className="text-danger">
                            {formik.errors.password}
                          </div>
                        ) : null}
                      </div>
                      <button type="submit" disabled={formik.isSubmitting} className="btn btn-primary">
                        {loginLocalization[lang].login} {formik.isSubmitting&&<FontAwesomeIcon icon={faSpinner} className="fa-spin fa-lg" />}
                      </button>
                    </form>
                    <div className="mt-3">
                      <Link className="text-decoration-none text-dark" to={'/register'}><FontAwesomeIcon icon={faUserPlus} className="me-2 text-danger"/>{loginLocalization[lang].registeAccount}</Link>
                    </div>
                    <div className="mt-3">
                    <Link className="text-decoration-none text-dark" to={'/reset-password'}><FontAwesomeIcon icon={faLock} className="me-2 text-danger"/>{loginLocalization[lang].forgetPass}</Link>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      </section>
    </>
  );
}
