import React, { Suspense, lazy } from 'react'
import HomeLoading from '../Loading/HomeLoading';
import Seo from './../Seo/Seo';
const LandingFooter =lazy(()=>import( '../Layout/Footer/LandingFooter'))
const Header =lazy(()=>import( './Header'))
const Worker =lazy(()=>import( './Worker'));
const HowWork =lazy(()=>import( './HowWork'));
const ClickAdvice =lazy(()=>import( './ClickAdvice'));
const Navbar =lazy(()=>import('../Layout/Navbar/Navbar'));
const Payment = lazy(()=>import('./Payment'));

export default function Landing() {
  return <>
  <Seo />
  <Suspense fallback={<HomeLoading/>}>
      <Navbar />
      <Header />
      <Worker />
      <HowWork />
      <ClickAdvice />
      <Payment />
      <LandingFooter />
  </Suspense>
  
  </>
}
