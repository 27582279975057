import React from "react";
import { Link } from "react-router-dom";

export default function Listitem({ items }) {
  return (
    <>
      <ul className="list-unstyled">
        {items &&
          items.map((item, index) => (
            <li key={index}>
              <Link
                to={item.link}
                className="link-secondary text-capitalize link-underline-opacity-0 link-offset-3"
              >
                {item.name}
              </Link>
            </li>
          ))}
      </ul>
    </>
  );
}
