export const commissionModelLocalization = {
    ar:{
        'heading':'نموذج العمولة',
        'subHeading':'اعثر على عمولتك لكل كمية تقوم ببيعها كما هو مذكور أدناه: يرجى العلم أنك مؤهل للحصول على العمولة القياسية، وسيتم إرسال أي تفاصيل أخرى لتغيير هيكل العمولة في المستقبل.',
        'type':'النوع',
        'from':'الكمية من',
        'to':'الكمية إلى',
        'commission':'العمولة',
        'stander':'قياسي',
        'elitPlus':'إيليت بلس',
        'elit':'إيليت',
        'diamond':'ماسي',
        'gold':'ذهبي',
        'silver':'فضي',

    },
    en:{
        'heading':'Commission Model',
        'subHeading':'Kindly find the commission structure per quantity as stated below: Please be informed that you are eligible for standard Commission, And, any further details for commission structure change will be communicated in the future.',
        'type':'Type',
        'from':'Quantity from',
        'to':'Quantity to',
        'commission':'Commission',
        'stander':'Stander',
        'elitPlus':'Elit plus',
        'elit':'Elit',
        'diamond':'Diamond',
        'gold':'Gold',
        'silver':'Silver',
    }
}