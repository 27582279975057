import React, { useContext, useState } from "react";
import BreadCrumb from "../Widget/BreadCrumb";
import { AuthContext } from "../../Contexts/AuthContext";
import { LangauageContext } from "../../Contexts/LangauageContext";
import { profileLocalization } from "../../Localization/profile";
import { navLocalization } from "../../Localization/nav";

export default function Profile() {
  const { auth } = useContext(AuthContext);
  const {lang} = useContext(LangauageContext);


  useState(()=>{
    if (auth === null) {
        window.location.replace('/')
    }
  });
  
  return (
    <>
      <BreadCrumb previous={navLocalization[lang].app} current={navLocalization[lang].profile} />
      <section className="py-5">
        <div className="container-fluid">
          <div className="mb-3">
            <h2>{profileLocalization[lang].basic}</h2>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="fullName" className="form-label">
                    {profileLocalization[lang].fullname}
                  </label>
                  <input
                    type="text"
                    id="fullName"
                    disabled
                    className="form-control"
                    value={auth.name}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    {profileLocalization[lang].email}
                  </label>
                  <input
                    type="text"
                    id="email"
                    disabled
                    className="form-control"
                    value={auth.email}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="affiliateNumber" className="form-label">
                    {profileLocalization[lang].affiliate}
                  </label>
                  <input
                    type="text"
                    id="affiliateNumber"
                    disabled
                    className="form-control"
                    value={auth.uuid}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mb-3">
            <h2>{profileLocalization[lang].wallet}</h2>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="approveBlance" className="form-label">
                    {profileLocalization[lang].approve}
                  </label>
                  <input
                    type="text"
                    id="approveBlance"
                    disabled
                    className="form-control"
                    value={auth.approved_balance}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="pendingBlance" className="form-label">
                  {profileLocalization[lang].pending}

                  </label>
                  <input
                    type="text"
                    id="pendingBlance"
                    disabled
                    className="form-control"
                    value={'0.00'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
