export const contactLocalization = {
    ar:{
        'slug':'يرجى أن تكون محددًا قدر الإمكان في استفسارك.',
        'subject':'الموضوع',
        'selectSubject':'قم باختيار الموضوع',
        'option1':'كيفية استخدام اولكس فوركس التسويق بالعمولة',
        'option2':'كيفية إضافة تفاصيل الدفع',
        'option3':'ليس لدي معرف ضريبي',
        'option4':'لدي دفعة مفقودة',
        'option5':'تغيير أو إعادة تعيين كلمة المرور',
        'option6':'لدي أوامر لم يتم تعقبها',
        'option7':'الرابط التابع الخاص بي غير قابل للتتبع',
        'option8':'أخرى',
        'message':'الرسالة',
        'submit':'موافقة',
    },
    en:{
        'slug':'Please be as specific as possible with your query.',
        'subject':'Subject',
        'selectSubject':'Select a subject',
        'option1':'How to use Olxforex Affiliate',
        'option2':'How to add payout details',
        'option3':'I don\'t have a tax ID',
        'option4':'I have a missing payment',
        'option5':'Change or Reset Password',
        'option6':'I have untracked orders',
        'option7':'My affiliate link is not trackable',
        'option8':'Others',
        'message':'Message',
        'submit':'Submit',
    }
}