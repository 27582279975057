import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function InvoiceState({ title, icon, invoiceHint, active = true }) {
  
  return (
    <>
      <div className="my-3">
        <small className="fw-bold">{title}</small>
        <div className="bg-light-subtle mt-3 shadow-sm d-flex p-3">
          <FontAwesomeIcon
            icon={icon}
            size="3x"
            className={active ? "text-success" : "text-secondary"}
          />
          <p className="mx-2 text-dark fs-3 text-opacity-50">
            <small className="fs-6">{invoiceHint}</small>
          </p>
        </div>
      </div>
    </>
  );
}
