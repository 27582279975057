
export const inviteLocalization = {
    ar:{
        'inviteDescription':'هى ميزه حصرية للمنتسبين لبرنامج اولكس فوركس تمكنهم من تكوين شبكة من الافراد ذوى الخبرة للتحقيق الارباح من خلال تسويق منتجات متجرنا، كل ما هو عليك دعوة اصدقائك من خلال البريد الالكترونى الخاص بهم لينضموا تحت حسابك.',
        'inviteCall':'ماذا تنتظر، ادع اصدقائك',
        'inviteFriends':'دعوة اصدقائك',
        'emptyNetworkers':'ليس هناك أي صلة الانتماء معك.',
        'registerLink':'نسخ رابط الاحالة خاص بك',

    },
    en:{
        'inviteDescription':'It is an exclusive feature for members of the OLXForex program that enables them to form a network of experienced individuals to achieve profits by marketing our store’s products. All you have to do is invite your friends through their email to join under your account.',
        'inviteCall':'What are you waiting for, invite your friends',
        'inviteFriends':'invite your friends',
        'emptyNetworkers':'There is no affiliation linkage with you.',
        'registerLink':'Copy your own registration link',

    }
}