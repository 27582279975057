
export const refferalLocalization = {
    ar:{
        'advertising':'الاعلان',
        'advertisingDescribtionOne':'يمكنك مشاركة عنوان الرابط الذي تم إنشاؤه أو الشعار الذي تم إنشاؤه.',
        'advertisingDescribtionTwo':'وفي كلتا الحالتين، يمكنك إضافة معلومات التتبع لفهم تحويلاتك بشكل أفضل.',
        'banner':'بيانات اللافته',
        'bannerDescribtion':'اللافتات هي صور يمكنك استخدامها أثناء الإعلان عن الفئات والحملات التجارية والعروض. يمكنك اختيار الحجم الأفضل على موقع الويب الخاص بك أو صفحة التواصل الاجتماعي.',
        'bannerSize':'حجم اللافته',
        'bannerLanguage':'لغة اللافته',
        'addtional':'بيانات تتبع إضافي',
        'addtionalDescribtion':'يمكنك إضافة معلمات التتبع الإضافية إلى عنوان الرابط. إنه يعمل مع عناوين URL التي تم إنشاؤها واللافتات التي تم إنشاؤها.',
        'subAffiliate':'معرف الشركة التابعة الفرعية',
        'channel':'قناة',
        'link':'عنوان الرابط الذي تم إنشاؤه',
        'linkDescribtion':'الرابط الذي يتعين عليك مشاركته مع جمهورك للحصول على عمولة مقابل أي طلبات يتم تقديمها عبر موقع اولكس فوركس.',
        'copy':'نسخ',
        'image':'اللافته الذي تم إنشاؤه',
        'imageDescribtion':'هذه هي معاينة شعار الترويج، لذا يمكنك نسخ أي من الحقول التي تم إنشاؤها والترويج لها عبر قنوات التواصل الاجتماعي أو على موقع الويب الخاص بك.',
        'preview':'عرض اللافتة',
        'url':'عنوان الرابط الذي تم إنشاؤه',
        'imageUrl':'رابط الصورة',
        'download':'تحميل',
        'imageUrlDescribtion':'الصورة التي سيتم استخدامها مع عنوان الرابط للإعلان للترويج عبر صفحة الوسائط الاجتماعية.',
        'code':'الترميز',
        'promotCode':'للترويج عبر موقع الويب الخاص بك.',
        'more':'المزيد من المعلومات',

    },
    en:{
        'advertising':'Advertising',
        'advertisingDescribtionOne':'You can share your generated URL or generated Banner.',
        'advertisingDescribtionTwo':'In both situations, you can add tracking information to understand your conversions better.',
        'banner':'Banner Parameters',
        'bannerDescribtion':'Banners are images you can use while advertising categories, commercial campaigns, and offers. You can choose the best size on your website or social media page.',
        'bannerSize':'BANNER SIZE',
        'bannerLanguage':'BANNER LANGUAGE',
        'addtional':'ADDITIONAL TRACKING',
        'addtionalDescribtion':'You can add your additional tracking parameters to the URL. It works for generated URLs and generated banners.',
        'subAffiliate':'Sub Affiliate ID',
        'channel':'Channel',
        'link':'Your generated URL',
        'linkDescribtion':'The link you have to share with your audience to get commission for any orders placed through Olxforex Website.',
        'copy':'Copy Code',
        'image':'Your generated banner',
        'imageDescribtion':'This is the promotion banner preview, so you can copy any of the generated fields and promote it via social media channels or on your website.',
        'preview':'BANNER PREVIEW',
        'url':'GENERATED URL',
        'imageUrl':'Image URL',
        'download':'Download',
        'imageUrlDescribtion':'Image to be used with ad URL to promote via social media page.',
        'code':'Code',
        'promotCode':'To promote via your website.',
        'more':'More information',
    }
}