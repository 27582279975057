import React, { useContext, useEffect, useState } from "react";
import BreadCrumb from "../Widget/BreadCrumb";
import { navLocalization } from "../../Localization/nav";
import { LangauageContext } from "../../Contexts/LangauageContext";
import { reportLocalization } from "../../Localization/report";
import ReportTable from "../Widget/ReportTable";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { AuthContext } from "../../Contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function CampaignDetials() {
  const { lang } = useContext(LangauageContext);
  const { auth } = useContext(AuthContext);

  const [reportData,setReportData] = useState(null);

  useEffect(()=>{
    if (auth === null) {
        window.location.replace('/')
    }
  });

  const getToday = ()=>{
    let date = new Date();

    return `${date.getUTCFullYear()}-${date.getUTCMonth() <10 ? ('0'+(date.getUTCMonth()+1)):(date.getUTCMonth()+1)}-${date.getUTCDate() <10 ? ('0'+(date.getUTCDate())):(date.getUTCDate())}`;
  }

  const initValues = {start: getToday() ,end: getToday()};
  const validation = Yup.object().shape({
    start: Yup.date().required(),
    end: Yup.date().required(),
  });

  const reportFormik = useFormik({
    initialValues: initValues,
    validationSchema: validation,
    onSubmit:(values,{setSubmitting})=>{
      setSubmitting(false);
    }
  });

  return (
    <>
      <BreadCrumb
        current={navLocalization[lang].conversions}
        previous={navLocalization[lang].app}
      />
      <section className="py-5">
        <form onSubmit={reportFormik.handleSubmit} className="row align-items-end">
          <div className="col-md-5">
            <div className="mb-3">
              <label htmlFor="start">{reportLocalization[lang].start}</label>
              <input type="date" id="start" onChange={reportFormik.handleChange} value={reportFormik.values.start} name="start" className="form-control" />
            </div>
            {reportFormik.errors.start && reportFormik.touched.start ? (
                  <div className="text-danger">{reportFormik.errors.start}</div>
                ) : null}
          </div>

          <div className="col-md-5">
            <div className="mb-3">
              <label htmlFor="end">{reportLocalization[lang].end}</label>
              <input type="date" id="end" onChange={reportFormik.handleChange} value={reportFormik.values.end} name="end" className="form-control" />
            </div>
            {reportFormik.errors.end && reportFormik.touched.end ? (
                  <div className="text-danger">{reportFormik.errors.end}</div>
                ) : null}
          </div>

          <div className="col-md-2">
            <div className="mb-3">
                <button className="btn btn-primary" disabled={reportFormik.isSubmitting} type="submit">{reportLocalization[lang].get} {reportFormik.isSubmitting&&<FontAwesomeIcon icon={faSpinner} className="fa-spin fa-lg" />}</button>
            </div>
          </div>
        </form>
      </section>
      <hr />

      {reportData ? <ReportTable dataSet={reportData} />:(<><section className="vh-100"><div className="d-flex justify-content-center"><small className="my-5 fs-3 text-danger">{reportLocalization[lang].empty}</small></div></section></>)}

    </>
  );
}
