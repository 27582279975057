import React, { useContext } from 'react'
import ar from "./../../Images/flags/saudi-arabia.png";
import en from "./../../Images/flags/usa.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { LangauageContext } from '../../Contexts/LangauageContext';

export default function LanguageSwitcher() {

  const {lang, changeLang} = useContext(LangauageContext);
  
  return <>
  <div className='d-flex align-items-center'>
    <FontAwesomeIcon icon={faLanguage} size='lg' />
  <button className="btn" onClick={changeLang}><img className="lang-image rounded-circle" src={lang ==='en'?ar:en} alt="language flag" title="language flag"/></button>
  </div>
  </>
}
