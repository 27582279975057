import React, { useContext, useEffect, useRef, useState } from "react";
import BreadCrumb from "../../Widget/BreadCrumb";
import { navLocalization } from "./../../../Localization/nav";
import { LangauageContext } from "../../../Contexts/LangauageContext";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../Contexts/AuthContext";
import { refferalLocalization } from "../../../Localization/refferal";
import { instanceOfAxios } from './../../../Api/instanceOfAxios';
import Swal from 'sweetalert2';
import { notifyLocalization } from "../../../Localization/notify";
import {saveAs} from 'file-saver'
import BannerView from "../../Widget/BannerView";

export default function RefferalLink() {
  const { lang } = useContext(LangauageContext);
  const {auth} = useContext(AuthContext);
  const urlParams = useParams();
  const url = `https://olxforex.com/affiliate/${auth.uuid}/${urlParams.category}`;
  // const url = `https://stage.olxforex.com/affiliate/${auth.uuid}/${urlParams.category}`;
  // const url = `http://127.0.0.1:8000/affiliate/${auth.uuid}/${urlParams.category}`;

  const [imageBanner, setImageBanner] = useState([]);
  const [subAffiliate, setSubAffiliate] = useState('');
  const [affiliateUrl, setAffiliateUrl] = useState('');
  const [channel, setChannel] = useState('');
  const [bannerSize, setBannerSize] = useState('120x600');
  const [bannerLang, setBannerLang] = useState(lang);

  const genrateUrl = useRef();

  const handelDownload = (imageLink, imageFile) => {
    
    saveAs(imageLink, imageFile);

  }

  const handelUrlCopy = (element)=>{
    navigator.clipboard.writeText(`${affiliateUrl}`);
 
     const Toast = Swal.mixin({
       toast: true,
       position: notifyLocalization[lang].position,
       showConfirmButton: false,
       timer: 3000,
       timerProgressBar: true,
       didOpen: (toast) => {
         toast.onmouseenter = Swal.stopTimer;
         toast.onmouseleave = Swal.resumeTimer;
       },
     });
 
     Toast.fire({
       icon: notifyLocalization[lang].success,
       title: notifyLocalization[lang].copySuccess,
     });
   }

  const handelCopy = (element)=>{
   navigator.clipboard.writeText(`<a href="${affiliateUrl}"><img src="${element}"/></a>`);

    const Toast = Swal.mixin({
      toast: true,
      position: notifyLocalization[lang].position,
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });

    Toast.fire({
      icon: notifyLocalization[lang].success,
      title: notifyLocalization[lang].copySuccess,
    });
  }

  const handelAddingParameter = async (code,element)=>{

    let url = new URL(genrateUrl.current.value);
    let regex = /\?/gm;
    let r = regex.test(url);

    if (!r) {
      url.searchParams.append(code, element.value);
    }else{
      if (element.value !== '') {

        url.searchParams.set(code, element.value);

      }else{
        url.searchParams.delete(code);
      }
    }
    setAffiliateUrl(url)
  }

  const handelInputChange = (e)=>{
    let element = e.target;

    if (element.name === 'subAffiliateID') {
      setSubAffiliate(element.value);
      handelAddingParameter('s1',element);
    }

    if (element.name === 'channel') {
      setChannel(element.value);
      handelAddingParameter('s2',element);
    }

    if (element.name === 'bannerSize') {
      setBannerSize(element.value);
    }

    if (element.name === 'bannerLanguage') {
      setBannerLang(element.value);
    }
  }

  useEffect(()=>{
    instanceOfAxios.post('/refferal/banner',{
      size:bannerSize,
      lang:bannerLang,
      category:urlParams.category
    },
    {
      headers:{
        "Accept-Language":lang,
        Authorization:`Bearer ${auth.token}`
      }
    }).then((response)=>{
      setImageBanner(response.data.data);
    }).catch((error)=>{
      Swal.fire({
        title: notifyLocalization[lang].error,
        text: notifyLocalization[lang].errorMessage,
        icon:'error'
      })
    })
  },[bannerLang,bannerSize]);

  useEffect(()=>{
    if (auth === null) {
      window.location.replace('/');
    }
    setAffiliateUrl(url)
  },[]);

  return (
    <>
      <BreadCrumb
        previous={navLocalization[lang].adverties}
        current={navLocalization[lang].offer}
      />

      <div className="bg-secondary-subtle p-3 mb-3">
        <h1 className="fs-4">{navLocalization[lang][urlParams.category]}</h1>
      </div>

      <section className="mb-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">

              <div className="mb-3">
                <h2 className="fs-6">{refferalLocalization[lang].advertising}</h2>
                <p className="text-secondary">
                  <strong>
                    {refferalLocalization[lang].advertisingDescribtionOne}
                  </strong>
                  {refferalLocalization[lang].advertisingDescribtionTwo}
                  
                </p>
              </div>

              <div className="mb-3">
                <h2 className="fs-6">{refferalLocalization[lang].banner}</h2>
                <p className="text-secondary">
                  {refferalLocalization[lang].bannerDescribtion}
                </p>
              </div>

              <div className="mb-3">
                <h2 className="fs-6">{refferalLocalization[lang].bannerSize}</h2>
                <select defaultValue={bannerSize} onChange={handelInputChange} name="bannerSize" className="form-select">
                  <option
                    value="120x600"
                  >
                    {navLocalization[lang][urlParams.category]} (120x600)
                  </option>

                  <option
                    value="160x600"
                    
                  >
                    {navLocalization[lang][urlParams.category]} (160x600)
                  </option>
                  
                  <option
                    value="300x250"
                  >
                    {navLocalization[lang][urlParams.category]} (300x250)
                  </option>
                  

                  <option
                    value="300x600"
                    
                  >
                    {navLocalization[lang][urlParams.category]} (300x600)
                  </option>

                  <option
                    value="320x100"
                  >
                    {navLocalization[lang][urlParams.category]} (320x100)
                  </option>

                  <option
                    value="320x50"
                  >
                    {navLocalization[lang][urlParams.category]} (320x50)
                  </option>

                  <option
                    value="320x480"
                  >
                    {navLocalization[lang][urlParams.category]} (320x480)
                  </option>

                  <option
                    value="500x500"
                  >
                    {navLocalization[lang][urlParams.category]} (500x500)
                  </option>

                  <option
                    value="720x90"
                  >
                    {navLocalization[lang][urlParams.category]} (720x90)
                  </option>

                  <option
                    value="728x90"
                  >
                    {navLocalization[lang][urlParams.category]} (728x90)
                  </option>

                  <option
                    value="800x800"
                  >
                    {navLocalization[lang][urlParams.category]} (800x800)
                  </option>

                  <option
                    value="828x1200"
                  >
                    {navLocalization[lang][urlParams.category]} (828x1200)
                  </option>

                  <option
                    value="970x250"
                  >
                    {navLocalization[lang][urlParams.category]} (970x250)
                  </option>

                  <option
                    value="980x250"
                  >
                    {navLocalization[lang][urlParams.category]} (980x250)
                  </option>

                  <option
                    value="990x250"
                  >
                    {navLocalization[lang][urlParams.category]} (990x250)
                  </option>

                  <option
                    value="1200x500"
                  >
                    {navLocalization[lang][urlParams.category]} (1200x500)
                  </option>

                  <option
                    value="1200x628"
                  >
                    {navLocalization[lang][urlParams.category]} (1200x628)
                  </option>

                  <option
                    value="1200x1500"
                  >
                    {navLocalization[lang][urlParams.category]} (1200x1500)
                  </option>

                  <option
                    value="1200x1200"
                  >
                    {navLocalization[lang][urlParams.category]} (1200x1200)
                  </option>

                  

                </select>
              </div>

              <div className="mb-3">
                <h2 className="fs-6">{refferalLocalization[lang].bannerLanguage}</h2>
                <select name="bannerLanguage" onChange={handelInputChange} defaultValue={bannerLang} className="form-select">
                  <option
                    value="en"
                  >
                    English
                    </option>
                    <option
                    value="ar"
                  >
                    Arabic
                    </option>
                </select>
              </div>

              

            </div>

            <div className="col-lg-6">
                <div className="mb-3">
                    <h2 className="fs-6">{refferalLocalization[lang].link}</h2>
                    <p className="text-secondary">
                    {refferalLocalization[lang].linkDescribtion}
                    </p>
                    <div className="d-flex gap-3">
                        <input type="url" ref={genrateUrl} className="form-control" readOnly value={affiliateUrl}/>
                        <button onClick={()=>{handelUrlCopy(genrateUrl)}} className="btn btn-primary">{refferalLocalization[lang].copy}</button>
                    </div>
                </div>

                <div className="mb-3">
                <h2 className="fs-6">{refferalLocalization[lang].addtional}</h2>
                <p className="text-secondary">
                {refferalLocalization[lang].addtionalDescribtion}
                </p>
              </div>

              <div className="mb-3">
              <label htmlFor="subAffiliateID" className="form-label">{refferalLocalization[lang].subAffiliate}</label>
                <input type="text" value={subAffiliate} id="subAffiliateID" placeholder="Sub Affiliate ID" name="subAffiliateID" onChange={handelInputChange} className="form-control" />
              </div>

              <div className="mb-3">
              <label htmlFor="channel" className="form-label">{refferalLocalization[lang].channel}</label>
                <input type="text" onChange={handelInputChange} value={channel} id="channel" placeholder="Channel" name="channel" className="form-control" />
              </div>

            </div>
          </div>

          <section>
          <div className="mb-3">
                    <h2 className="fs-6">{refferalLocalization[lang].image}</h2>
                    <p className="text-secondary">
                    {refferalLocalization[lang].imageDescribtion}
                    </p>
                </div>
            <div className="row">

              
            {imageBanner&&imageBanner.map((banner,index)=> <BannerView key={index} imageLink={banner.image} imageFile={banner.file} downloadBtn={handelDownload} copyBtn={handelCopy}/>)}

            </div>
          </section>
        </div>
      </section>
    </>
  );
}
