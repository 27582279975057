import {
  faBullhorn,
  faFileInvoice,
  faHeadset,
  faHome,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import Listitem from "../Listitem/Listitem";
import { LangauageContext } from "../../../Contexts/LangauageContext";
import { navLocalization } from "../../../Localization/nav";
import { Link } from "react-router-dom";
import { AuthContext } from './../../../Contexts/AuthContext';

export default function Sidebar() {
  const { lang } = useContext(LangauageContext);
  const { auth } = useContext(AuthContext);
  const status = (auth!== null ? auth.status : 'pending');
  const advertinigItemList = [
    {
      name: navLocalization[lang].offer,
      link: "offer",
    },
    {
      name: navLocalization[lang].invite,
      link: "affilates/network",
    },
  ];

  const walletItemList = [
    {
      name: navLocalization[lang].wallet,
      link: "wallet",
    },
    // {
    //   name: navLocalization[lang].walletWithdraw,
    //   link: "",
    // },
  ];

  const reportItemList = [
    // {
    //   name: navLocalization[lang].conversions,
    //   link: "report/campaign/detials",
    // },
    {
      name: navLocalization[lang].commissionModel,
      link: "support/commission",
    },
    // {
    //   name: navLocalization[lang].invoices,
    //   link: "",
    // },
    // {
    //   name: navLocalization[lang].downloads,
    //   link: "",
    // },
  ];

  const supportItemList = [
    // {
    //   name:'FAQs',
    //   link:''
    // },
    {
      name: navLocalization[lang].contact,
      link: "support/contact",
    },
  ];

  return (
    <>
      <div className="bg-dark shadow-sm olx-sidebar">
        <div className="accordion accordion-flush" id="accordionFlushExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <Link
                className="accordion-button collapsed sidebar-home-button"
                role="button"
                to={'/app'}
              >
                <FontAwesomeIcon
                  icon={faHome}
                  className="me-2 text-danger"
                  size="lg"
                />{" "}
                {navLocalization[lang].home}
              </Link>
            </h2>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
                disabled={status==='pending'?true:false}

              >
                <FontAwesomeIcon
                  icon={faBullhorn}
                  className="me-2 text-danger"
                  size="lg"
                />{" "}
                {navLocalization[lang].adverties}
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <Listitem items={advertinigItemList}></Listitem>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFour"
                aria-expanded="false"
                aria-controls="flush-collapseFour"
                disabled={status==='pending'?true:false}

              >
                <FontAwesomeIcon
                  icon={faWallet}
                  className="me-2 text-danger"
                  size="lg"
                />{" "}
                {navLocalization[lang].wallet}
              </button>
            </h2>
            <div
              id="flush-collapseFour"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <Listitem items={walletItemList}></Listitem>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
                disabled={status==='pending'?true:false}

              >
                <FontAwesomeIcon
                  icon={faFileInvoice}
                  className="me-2 text-danger"
                  size="lg"
                />{" "}
                {navLocalization[lang].report}
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <Listitem items={reportItemList}></Listitem>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
                disabled={status==='pending'?true:false}
              >
                <FontAwesomeIcon
                  icon={faHeadset}
                  className="me-2 text-danger"
                  size="lg"
                />{" "}
                {navLocalization[lang].support}
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <Listitem items={supportItemList}></Listitem>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
