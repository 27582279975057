import React, { useContext, useState } from "react";
import { AuthContext } from "./../../Contexts/AuthContext";
import { LangauageContext } from "./../../Contexts/LangauageContext";
import BreadCrumb from "./../Widget/BreadCrumb";
import { navLocalization } from "./../../Localization/nav";
import { inviteLocalization } from "./../../Localization/invite";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { modalLocalization } from "../../Localization/modal";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { validationLocalization } from './../../Localization/validation';
import Swal from 'sweetalert2';
import { instanceOfAxios } from './../../Api/instanceOfAxios';
import { notifyLocalization } from './../../Localization/notify';
import { NetworkContext } from "../../Contexts/NetworkContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
// import { profileLocalization } from './../../Localization/profile';
import OrganizationChart from "@dabeng/react-orgchart";

export default function Network() {
  const { auth } = useContext(AuthContext);
  const { lang } = useContext(LangauageContext);
  const { network } = useContext(NetworkContext);
  const [show, setShow] = useState(false);
  const intitValues = {
    inviteEmail: '',
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const validation = Yup.object().shape({
    inviteEmail: Yup.string()
      .email('Please provide valid email')
      .required("This filed is required")
  });

  const handelRegisterLink = () => {
    let url = 'https://affiliate.olxforex.com/register?invitor=' + auth.uuid;
    Swal.fire({
      title: notifyLocalization[lang].successCreate,
      text: url,
      icon: notifyLocalization[lang].success,
      allowOutsideClick: false,
      confirmButtonText: notifyLocalization[lang].copy,
    }).then((result) => {
      navigator.clipboard.writeText(url);
      const Toast = Swal.mixin({
        toast: true,
        position: notifyLocalization[lang].position,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });

      Toast.fire({
        icon: notifyLocalization[lang].success,
        title: notifyLocalization[lang].copySuccess,
      });
    });
  };

  useState(() => {
    if (auth === null) {
      window.location.replace("/");
    }
  });

  const formik = useFormik({
    initialValues: intitValues,
    validationSchema: validation,
    onSubmit: (values, { resetForm , setSubmitting}) => {

      instanceOfAxios.post('/send-invite',
        values,
        {
          headers: {
          "Accept-Language":lang,
            Authorization: `Bearer ${auth.token}`
          }
        }).then((response) => {
          // jquery('#staticBackdrop').modal('hide');
          Swal.fire({
            title: validationLocalization[lang].success,
            icon: "success",
          });
          resetForm();
          setShow(false);
        }).catch((error) => {
        setSubmitting(false)

          let errorResponse = error.response?.data;
          // console.log(error);
          Swal.fire({
            title: validationLocalization[lang].error,
            text: errorResponse.message['errors'] ?? errorResponse.data.inviteEmail,
            icon: "error",
          });
          // console.log(errorResponse);
        });


    }
  })

  return (
    <>
      <BreadCrumb
        previous={navLocalization[lang].app}
        current={navLocalization[lang].invite}
      />

      <section>
        <div className="mb-3">
          <h1 className="text-capitalize">{navLocalization[lang].invite}</h1>
          <p className="lead">{inviteLocalization[lang].inviteDescription}</p>
        </div>
        <div className="bg-warning-subtle p-5 text-center rounded-3 mb-3">
          <h2 className="mb-3">{inviteLocalization[lang].inviteCall}</h2>
          <Button onClick={handleShow} variant="primary m-3">
            {inviteLocalization[lang].inviteFriends}
          </Button>

          <Button onClick={handelRegisterLink} variant="success">
            {inviteLocalization[lang].registerLink}
          </Button>

        </div>
        <div className="mb-3 table-responsive">
          {network !== null ?<>
          {/* <table className="table table-striped mb-5">
            <thead>
              <tr>
              <th scope="col">{profileLocalization[lang].fullname}</th>
              <th scope="col">{profileLocalization[lang].status}</th>
              </tr>
            </thead>
            <tbody>
              {network.map((friend)=> (<tr key={friend.id}>
                <td>{friend.full_name}</td>
                <td>{friend.status}</td>
              </tr>))}
            </tbody>
          </table> */}
          <div>
            <OrganizationChart datasource={network} />
          </div>
          </> :(<div className="bg-secondary-subtle p-5 text-center rounded-3 mb-3">
            <p className="lead fw-bold">
              {inviteLocalization[lang].emptyNetworkers}
            </p>
            <Button onClick={handleShow} variant="primary m-3">
              {inviteLocalization[lang].inviteFriends}
            </Button>

            <Button onClick={handelRegisterLink} variant="success">
            {inviteLocalization[lang].registerLink}
          </Button>

          </div>)}
        </div>
      </section>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{inviteLocalization[lang].inviteFriends}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              <label className="form-label" htmlFor="inviteEmail">Friends Email</label>
              <input type="email" value={formik.values.inviteEmail} onChange={formik.handleChange} id="inviteEmail" name="inviteEmail" className="form-control" />
              {formik.errors.inviteEmail && formik.touched.inviteEmail ? (<div className="text-danger">
                {formik.errors.inviteEmail}
              </div>) : null}
            </div>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                {modalLocalization[lang].close}
              </Button>
              <Button variant="primary" type="submit" disabled={formik.isSubmitting}>
                {modalLocalization[lang].invite} {formik.isSubmitting&&<FontAwesomeIcon icon={faSpinner} className="fa-spin fa-lg" />}
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
