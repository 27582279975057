export const loginLocalization = {
    ar:{
        'email':'البريد الالكترونى',
        'password':'كلمة السر',
        'rePassword':'اعادة كتابة كلمة السر',
        'login':'تسجيل الدخول',
        'registe':'تسجيل حساب',
        'fname':'الاسم الاول',
        'lname':'الاسم الاخير',
        'mobile':'رقم الهاتف',
        'haveAccount':'لدي حساب للدخول',
        'registeAccount':'تسجيل حساب جديد',
        'forgetPass':'نسيت كلمة المرور؟',
        'reset':'استرجاع الحساب',
        'resetPass':'تغير كلمة السر',
        'countries':'الدولة',
        'registeSucess':'لقد تم تسجيلك بنجاح، سوف نقوم بمراجعة البيانات و ارسال بريد الالكترونى يتضمن تفاصيل الدخول، شكرا للاستخدامك خدمات اولكس فوركس',
        'resetSuccess':'لقد تم ارسال طلبك بنجاح، يرجى مراجعة بريدك الالكترونى',
        'selectCountry':'اختار الدولة',
    },
    en:{
        'email':'Email address',
        'password':'Password',
        'rePassword':'Re-type password',
        'login':'Login',
        'fname':'First name',
        'lname':'Last name',
        'registe':'Sign up',
        'mobile':'Phone number',
        'resetPass':'change password',
        'haveAccount':'I have an account',
        'registeAccount':'Register new account',
        'forgetPass':'Forget my password?',
        'reset':'Reset account',
        'countries':'Country',
        'registeSucess':'You have been registered successfully. We will review the data and send an email containing the login details. Thank you for using OLXForex services.',
        'resetSuccess':'Your request has been sent successfully, please check your email',
        'selectCountry':'Choose Country',

    }
}