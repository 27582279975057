export const accountLocalization = {
    ar:{
        'overview':'نظرة عامة على الحساب',
        'walletOverview':'نظرة عامة على المحفظة',
        'transactionOverview':'معاملات المحفظة',
    },
    en:{
        'overview':'Account Overview',
        'walletOverview':'Wallet Overview',
        'transactionOverview':'Wallet transactions',
    }
}