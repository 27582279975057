import React, { useContext} from "react";
import { LangauageContext } from "../../../Contexts/LangauageContext";
import { offerLocalization } from "../../../Localization/offer";
import { refferalLocalization } from "../../../Localization/refferal";

export default function OfferTable({ product, handelClick , handelProductInfoClick}) {
  const { lang } = useContext(LangauageContext);


  return (
    <>
    <div className="col-md-12">
      <div className="px-2">
      <div className="row align-items-center">
                <div className="col-md-2">
                  <img
                    src={product.image[0].logo}
                    alt={product.title}
                    title={product.title}
                    className="offer-detials-image"
                  />
                </div>
                <div className="col-md-8">
                <div className="mb-2">
                <strong>{offerLocalization[lang].product}</strong> {product.title}
                </div>
                <div className="mb-2">
                <strong>{offerLocalization[lang].price}</strong> {product.price}$

                </div>
                  <div className="mb-2">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdropProduct"
                    data-desc={product.description}
                    data-title={product.title}
                    onClick={handelProductInfoClick}
                  >
                    {refferalLocalization[lang].more}
                  </button>
                  </div>
                </div>
                <div className="col-md-2">
                  <button
                    className="btn btn-dark"
                    data-id={product.id}
                    onClick={handelClick}
                  >
                    {offerLocalization[lang].offerLink}
                  </button>
                </div>
      </div>
      </div>
    </div>
    <hr />
    </>
  );
}
