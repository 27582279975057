import React, { useContext, useEffect, useState } from "react";
// import Chart from "react-apexcharts";
import { LangauageContext } from "../../Contexts/LangauageContext";
import { widgetLocalization } from "../../Localization/widget";
const Chart = React.lazy(()=>import('react-apexcharts'));
export default function ChartState({ dataSet= []}) {

  const {lang} = useContext(LangauageContext);


  const[categoriesArray,setCategoriesArray] = useState([]);
  const[dataArray,setDataArray] = useState([]);

  useEffect(()=>{
    if (dataSet.length > 0) {
      // console.log(dataSet);
      let categoryCounter = [];
      let dataCounter = [];

      dataSet.forEach(element => {
        categoryCounter.push(element.date);
        dataCounter.push(element.visits);
      });

      setCategoriesArray(categoryCounter);
      setDataArray(dataCounter);
    }
  },[dataSet]);

  const options = {
    chart: {
      id: "line",
    },
    xaxis: {
      categories: categoriesArray,
    },
  };
  const data = [
    {
      name: widgetLocalization[lang].visits,
      data: dataArray,
    },
  ];
  return (
    <div className="my-3">
      {" "}
      <Chart type="line" height="500" options={options} series={data} />
    </div>
  );
}
