import React, { createContext, useContext, useEffect, useState } from 'react'
import { instanceOfAxios } from '../Api/instanceOfAxios';
import { AuthContext } from './AuthContext';

export const NetworkContext = createContext();

export default function NetworkContextProvider(props) {
  const [network,setNetwork] =useState(null);
  const {auth} = useContext(AuthContext);

  useEffect(()=>{
    instanceOfAxios.get('/network/friends',{headers:{
      Authorization:`Bearer ${auth.token}`
    }
  }).then(response =>{
        setNetwork(response.data.data);
    }).catch(error=>{
        console.log(error);
    })
  },[])

  return <NetworkContext.Provider value={{network}}>
    {props.children}
  </NetworkContext.Provider> 
}
