import React, { useContext, useEffect, useRef, useState } from "react";
import BreadCrumb from "../../Widget/BreadCrumb";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { instanceOfAxios } from "../../../Api/instanceOfAxios";
import { AuthContext } from "../../../Contexts/AuthContext";
import { LangauageContext } from "./../../../Contexts/LangauageContext";
import { validationLocalization } from "./../../../Localization/validation";
import Lodar from "../../Loading/Lodar";
import OfferTable from "./OfferTable";
import { navLocalization } from "../../../Localization/nav";
import { notifyLocalization } from "../../../Localization/notify";
import InfiniteScroll from 'react-infinite-scroll-component';
import { offerLocalization } from "../../../Localization/offer";
import { modalLocalization } from './../../../Localization/modal';
// const Swal = React.lazy(()=>import('sweetalert2'));

export default function OfferDetials() {
  const urlParams = useParams();
  const productModal = useRef();

  const { auth } = useContext(AuthContext);
  const { lang } = useContext(LangauageContext);

  const [products, setProducts] = useState(null);
  const [productsDesc, setProductsDesc] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);

  const handelClick = (e) => {
    const id = e.target.dataset.id;
    instanceOfAxios
      .post(
        "product/affiliate/link",
        { product: id },
        {
          headers: {
          "Accept-Language":lang,
            Authorization: `Bearer ${auth.token}`,
          },
        }
      )
      .then((response) => {
        let url = response.data.data.url;

        let timerInterval;
        Swal.fire({
          title: notifyLocalization[lang].loading,
          html: `${notifyLocalization[lang].reserved} <b></b> ${notifyLocalization[lang].milliseconds}.`,
          timer: 2000,
          allowOutsideClick: false,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const timer = Swal.getPopup().querySelector("b");
            timerInterval = setInterval(() => {
              timer.textContent = `${Swal.getTimerLeft()}`;
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            Swal.fire({
              title: notifyLocalization[lang].successCreate,
              text: url,
              icon: notifyLocalization[lang].success,
              allowOutsideClick: false,
              confirmButtonText: notifyLocalization[lang].copy,
            }).then((result) => {
              navigator.clipboard.writeText(url);
              const Toast = Swal.mixin({
                toast: true,
                position: notifyLocalization[lang].position,
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.onmouseenter = Swal.stopTimer;
                  toast.onmouseleave = Swal.resumeTimer;
                },
              });

              Toast.fire({
                icon: notifyLocalization[lang].success,
                title: notifyLocalization[lang].copySuccess,
              });
            });
          }
        });
      })
      .catch((error) => {
        let errorResponse = error.response.data;
        Swal.fire({
          title: validationLocalization[lang].error,
          text: errorResponse.message,
          icon: "error",
        });
      });
  };

  const loadData = (page) => {
    instanceOfAxios
      .post(
        `/product/list?page=${page}`,
        { type: urlParams.category },
        {
          headers: {
            "Accept-Language": lang,
            Authorization: `Bearer ${auth.token}`,
          },
        }
      )
      .then((res) => {
        let product = products === null ? res.data.data.data : products.concat(Array.from(res.data.data.data))
        // console.log(product);
        setProducts(product);
        res.data.data.next_page_url === null ? setHasMore(false) : setHasMore(true);
        let index = pageIndex + 1;
        setPageIndex(index);
      })
      .catch((error) => {
        let errorResponse = error.response.data;
        Swal.fire({
          title: validationLocalization[lang].error,
          text: errorResponse.message,
          icon: "error",
        });
      });
  }

  const fetchMoreData = () => {
    loadData(pageIndex);
    console.log(pageIndex, hasMore);
  };

  useEffect(() => {
    if (auth === null) {
      window.location.replace("/");
    }
    loadData(pageIndex);
  }, []);

  const handelProductInfoClick = (e) => {
    let btn = e.target;
    let dataset = btn.dataset;

    productModal.current.querySelector(".modal-title").innerText = dataset.title;
    setProductsDesc(dataset.desc);

    // setProductsDesc(dataset.id);
    // console.log(dataset, productModal, selectedProduct);
  };

  return (
    <>
      <BreadCrumb
        previous={navLocalization[lang].offer}
        current={navLocalization[lang][urlParams.category]}
      />
      <div className="container-fluid">
        <div className="row">
          {products === null ? <Lodar /> : (<InfiniteScroll
            dataLength={products.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<Lodar />}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>{offerLocalization[lang].loadAll}</b>
              </p>
            }
          >
            {products.map((product) => <OfferTable key={product.id} product={product} handelClick={handelClick} handelProductInfoClick={handelProductInfoClick} />)}
          </InfiniteScroll>

          )}

        </div>
      </div>

      {/* <!-- Modal --> */}
      <div
        ref={productModal}
        className="modal fade"
        id="staticBackdropProduct"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropProductLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropProductLabel">
                Modal title
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p
                className="fixed-description text-dark d-block text-opacity-75"
                dangerouslySetInnerHTML={{ __html: productsDesc }}
              ></p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                            {modalLocalization[lang].close}
              </button>
              {/* <button type="button" className="btn btn-primary">Understood</button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
