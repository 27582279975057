import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function WalletState({
  title,
  icon,
  balance = 0,
  currency = "USD",
  active = true,
  canUpdate = false,
  updateHandler,
}) {
  return (
    <>
      <div className="my-3">
        <small className="fw-bold">{title}</small>
        <div className="bg-light-subtle mt-3 shadow-sm d-flex p-3 position-relative">
          <FontAwesomeIcon
            icon={icon}
            size="3x"
            className={active ? "text-success" : "text-secondary"}
          />
          <p className="mx-2 fs-3">{balance}</p>
          <p className="fs-3">{currency}</p>
          {canUpdate?(<div className="position-absolute end-0 bottom-0  refresh">
            <button role="button" className="btn btn-light bg-transparent border-0" onClick={updateHandler}><FontAwesomeIcon icon={faRefresh} /></button>
          </div>):null}
        </div>
      </div>
    </>
  );
}
