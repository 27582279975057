export const widgetLocalization = {
    ar: {
        'approvedCommission': 'رصيدك من عمولات عمليات الشراء',
        'pendingCommission': 'العمولات المعلقة',
        'invoices': 'الفواتير الخاصة بك',
        'usd': 'دولار',
        'invoiceHint': 'ليس لديك فواتير بعد',
        'dashboard': 'لوحة التحكم',
        'today': 'اليوم',
        'last7': 'اخر 7 أيام',
        'last30': 'اخر 30 يوم',
        'last90': 'اخر 90 يوم',
        'campaign': 'عدد الحملات',
        'clicks': 'عدد الزيارات',
        'reached': 'عدد المبيعات',
        'commissions': 'قيمة العملات',
        'visits': 'الزيارات',
        'product': 'اسم المنتج',
        'next': 'التالى',
        'previous': 'السابق',
        'walletProcess':"وسائل السحب من المحفظة",
        'AccountReview':"حسابك قيد المراجعة، وستكون جميع وظائف التطبيق متاحة عند قبول حسابك.",
        

    },
    en: {
        'approvedCommission': 'Your balance of purchase commissions',
        'pendingCommission': 'Commissions Pending',
        'invoices': 'Your Invoices',
        'usd': 'USD',
        'invoiceHint': 'You don\'t have invoices yet',
        'dashboard': 'Dashboard',
        'today': 'Today',
        'last7': 'Last 7 days',
        'last30': 'Last 30 days',
        'last90': 'Last 90 days',
        'campaign': '# of Campaign',
        'clicks': '# of clicks',
        'reached': '# of Purchase',
        'commissions': 'Amount of Commissions',
        'visits': 'Clicks',
        'product': 'Product name',
        'next': 'Next',
        'previous': 'Previous',
        'walletProcess':"Withdraw processes methods",
        'AccountReview':'Your Account is Under Review, All application functionality will be available when your account is accepted.'
    }
}