import React, { createContext, useEffect, useState } from 'react'
import { instanceOfAxios } from './../Api/instanceOfAxios';

export const SocialContext = createContext();

export default function SocialContextProvider(props) {
  const [social,setSocial] =useState([]);

  useEffect(()=>{
    instanceOfAxios.get('/social',{headers:{
      "Accept-Language":'en',
      'keyToken':"U3qMG7RsDDoVgzcJjkzOSxbCydTggBqhviOWSl9bZVztwUkDgUT2T7g9y8kMavm1"
    }
  }).then(response =>{
        setSocial(response.data.data);
    }).catch(error=>{
        console.log(error);
    })
  },[])

  return <SocialContext.Provider value={{social}}>
    {props.children}
  </SocialContext.Provider> 
}
