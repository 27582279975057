import React from "react";
import { Helmet } from "react-helmet";

export default function Seo({ title, description, keywords }) {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={
            description ??
            "Olxforex Affaliate program, ultimate Portal for Content Creators, Influencers or Affiliates. Earn commissions using your website, blog or social media."
          }
        />
        <meta
          name="keywords"
          content={
            keywords ??
            "Olxforex, Affaliate program, Olxforex Affaliate program"
          }
        />
        <meta property="og:description" content={description ??
          "Olxforex Affaliate program, ultimate Portal for Content Creators, Influencers or Affiliates. Earn commissions using your website, blog or social media."} />

        <meta property="og:title" content={title ?? "Olxforex | Affaliate"} />

        <meta property="og:url" content={window.location.href} />

        <link rel="canonical" href={window.location.href} />


        <title>{title ?? "Olxforex | Affaliate"}</title>
      </Helmet>
    </>
  );
}
