export const offerLocalization = {
    ar:{
        'image':'الصورة',
        'product':'اسم المنتح',
        'action':'الاجراءات',
        'offerLink':'الحصول على رابط الإعلان',
        'getStart':'بدء خدمة انشاء روابط الإحالة',
        'getStartProduct':'بدء خدمة انشاء روابط الإحالة للمنتجات',
        'price':'سعر المنتج',
        'loadAll':'تم مشاهدة جميع البيانات'
    },
    en:{
        'image':'Image',
        'product':'Product Name',
        'action':'Action',
        'offerLink':'Get Advertining Link',
        'getStart':'Start a referral link creation service',
        'getStartProduct':'Start a referral link product creation service',
        'price':'Product price',
        'loadAll':'You have seen it all'
    }
}