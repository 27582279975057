import React, { useContext } from "react";
import logo from "../../../Images/logo.webp";
import { Link } from "react-router-dom";
import LanguageSwitcher from "../../Language/LanguageSwitcher";
import { navLocalization } from "../../../Localization/nav";
import { LangauageContext } from "../../../Contexts/LangauageContext";
import { AuthContext } from "../../../Contexts/AuthContext";
import { instanceOfAxios } from "../../../Api/instanceOfAxios";
import Swal from "sweetalert2";
import { validationLocalization } from "../../../Localization/validation";
import ResetPassword from "../ResetPassword/ResetPassword";
import { loginLocalization } from './../../../Localization/login';

export default function Navbar() {
  const {lang} = useContext(LangauageContext);
  const {auth,authCreditional} = useContext(AuthContext);

  const logoutHandel = ()=>{
    instanceOfAxios.post('/logout',null,{
      headers:{
        'Authorization':`Bearer ${auth.token}`
      }
    }).then((respond)=>{
      // unset user data
      sessionStorage.removeItem('user');
      authCreditional(null);
      // redirect to login page
      window.location.replace('/');
    }).catch((error)=>{
      let errorResponse = error.response.data;
      Swal.fire({
        title:validationLocalization[lang].error,
        text:errorResponse.message??errorResponse.data.message,
        icon:'error'
      });
    });

  };

  

  return (
    <>

      <nav className="navbar sticky-top navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <Link className="navbar-brand" to={auth?"/app":'/'}>
            <img
              src={logo}
              alt="olxforex"
              title="olxforex"
              className="img-fluid navbar-logo"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            {auth === null ? (
                <>
                  <li className="nav-item">
                    <Link className="nav-link" to={"/login"}>
                      {loginLocalization[lang].login}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to={"/register"}>
                      {loginLocalization[lang].registe}
                    </Link>
                  </li>
                </>
              ) : (
                <li className="nav-item dropdown dropdown-center ">
                  <Link
                    className="nav-link dropdown-toggle active fw-bold"
                    aria-current="page"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {auth?.name}
                  </Link>
                  <ul className="dropdown-menu dropdown-end">
                    <li>
                      <Link className="dropdown-item" to={"/app/profile"}>
                        {navLocalization[lang].profile}
                      </Link>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                      >
                        {navLocalization[lang].changePassowrd}
                      </button>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <button className="dropdown-item" onClick={logoutHandel}>
                        {navLocalization[lang].signOut}
                      </button>
                    </li>
                  </ul>
                </li>
              )}
              <li className="nav-item">
              <LanguageSwitcher />
            </li>
            </ul>
          </div>
        </div>
      </nav>

    <ResetPassword  />

    </>
  );
}
