import React, { useContext } from "react";
import { LangauageContext } from "../../../Contexts/LangauageContext";
import { footerLocalization } from "../../../Localization/footer";

export default function Footer() {
  const {lang} = useContext(LangauageContext);

  return (
    <>
      <footer>
        <div className="bg-dark text-light p-2">
          <p>
            {footerLocalization[lang].all} &copy; {footerLocalization[lang].reserved},{" "}
            <a
              href="https://olxforex.com"
              className="text-light"
              target="_blank"
              rel="noopener noreferrer"
            >
              Olxforex
            </a>
          </p>
        </div>
      </footer>
    </>
  );
}
