export const profileLocalization = {
    ar:{
        'basic':'المعلومات الأساسية',
        'fullname': 'الأسم الكامل',
        'affiliate':'الرقم التعريفي',
        'email':'البريد الالكترونى',
        'wallet':'معلومات المحفظة',
        'approve':'الرصيد المفعل',
        'pending':'الرصيد المعلق',
        'status':'حالة لحساب'
    },
    en:{
        'basic':'Basic Information',
        'fullname': 'Full name',
        'affiliate':'Affiliate Number',
        'email':'Email',
        'wallet':'Wallet Information',
        'approve':'Approve Blance',
        'pending':'Pending Blance',
        'status':'Status'
    }
}