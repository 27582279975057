import axios from "axios";

export const instanceOfAxios = axios.create({
  // baseURL: "http://127.0.0.1:8000/api/affiliate",
  // baseURL: "https://stage.olxforex.com/api/affiliate",
  baseURL: "https://olxforex.com/api/affiliate",
  headers:{
    "Content-Type":'application/json',
    "Accept":'application/json',
  }
});

// Add a request interceptor
instanceOfAxios.interceptors.request.use(function (config) {
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
instanceOfAxios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});
