import React, { useContext } from "react";
import { LangauageContext } from "../../Contexts/LangauageContext";
import { widgetLocalization } from "../../Localization/widget";

export default function TableState({handelChangeLenght, dataSet = []}) {
  const {lang} = useContext(LangauageContext);

  return (
    <>
      <section className="my-3">
        <div className="d-flex justify-content-between mb-3">
          <h2 className="text-opacity-75 text-dark">{widgetLocalization[lang].dashboard}</h2>

          <form>
            <select onChange={handelChangeLenght} className="form-control" name="period" id="period">
              <option value="0">{widgetLocalization[lang].today}</option>
              <option value="-7">{widgetLocalization[lang].last7}</option>
              <option value="-30">{widgetLocalization[lang].last30}</option>
              <option value="-90">{widgetLocalization[lang].last90}</option>
            </select>
          </form>
        </div>
        <div className="table-responsive">
          <table className="table table-light table-striped">
            <thead>
              <tr>
                {/* <th>{widgetLocalization[lang].campaign}</th> */}
                <th>{widgetLocalization[lang].clicks}</th>
                <th>{widgetLocalization[lang].reached}</th>
                <th>{widgetLocalization[lang].commissions}</th>
              </tr>
            </thead>

            <tbody>
              {dataSet? dataSet.map((data,index)=> (<tr key={index}>
                {/* <td>{(data.campaign)}</td> */}
                <td>{data.clicks}</td>
                <td>{data.buy}</td>
                <td>{data.commissions} USD</td>
              </tr>)) : (<tr>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0 USD</td>
              </tr>)}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}
