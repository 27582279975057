import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import logo from '../../Images/logo.webp';
import { LangauageContext } from '../../Contexts/LangauageContext';
import { notFoundLocalization } from '../../Localization/notFound';
import { AuthContext } from './../../Contexts/AuthContext';

export default function Notfound() {
	const {lang} = useContext(LangauageContext);
    const { auth } = useContext(AuthContext);


  return <>

<section className="page_404">
	<div className="container">
		<div className="row">	
		<div className="col-sm-12 ">
		<div className="col-sm-10 offset-sm-1  text-center">
			<div><img src={logo} alt="olxforex" title='olxforex' className='img-404-logo' /></div>
		<div className="four_zero_four_bg">
			
			<h1 className="text-center ">404</h1>
		</div>
		
		<div className="contant_box_404">
		<h3 className="h2">
		{notFoundLocalization[lang].title}
		</h3>
		
		<p>{notFoundLocalization[lang].subTitle}</p>
		
		<Link to={auth === null ? '/':'app'} className="link_404">{notFoundLocalization[lang].button}</Link>
	</div>
		</div>
		</div>
		</div>
	</div>
</section>

  </>
}
