
import React, { createContext, useContext, useEffect, useState } from 'react';
import { instanceOfAxios } from './../Api/instanceOfAxios';
import { AuthContext } from './AuthContext';

export const ChartContext = createContext();

const ChartContextProvider = (props)=>{

    const[chartData,setChartData]= useState([]);
    const[overAll,setOverAll]= useState([]);

    const {auth} = useContext(AuthContext);

    useEffect(()=>{
        setChartData(getChartData());
        setOverAll(getOverAll());
    },[]);

    const ChartChangeLength= (e)=>{
        const length = e.target.value;
        setChartData(getChartData(length));
        setOverAll(getOverAll(length));
    }

    const getChartData = (legnth = 0)=>{
        instanceOfAxios.post('chart/get/visits',{days: legnth},{
            headers:{
                Authorization: `Bearer ${auth.token}`
            }
        }).then((res)=>{
            setChartData(res.data.data);
        }).catch((error)=>{

        })
    }

    const getOverAll = (legnth = 0)=>{
        instanceOfAxios.post('chart/get/overall',{days: legnth},{
            headers:{
                Authorization: `Bearer ${auth.token}`
            }
        }).then((res)=>{
            setOverAll(res.data.data);
        }).catch((error)=>{

        })
    }

    return <ChartContext.Provider value={{overAll,chartData,ChartChangeLength}}>
        {props.children}
    </ChartContext.Provider>
}

export default ChartContextProvider;