import React, { useContext, useEffect, useState } from "react";
import { faFile, faWallet } from "@fortawesome/free-solid-svg-icons";
import WalletState from "../Widget/WalletState";
import InvoiceState from "../Widget/InvoiceState";
import TableState from "../Widget/TableState";
import ChartState from "../Widget/ChartState";
import { AuthContext } from "../../Contexts/AuthContext";
import { ChartContext } from "../../Contexts/ChartContext";
import { LangauageContext } from './../../Contexts/LangauageContext';
import { accountLocalization } from "../../Localization/account";
import { widgetLocalization } from "../../Localization/widget";
import { instanceOfAxios } from "../../Api/instanceOfAxios";
import AccountState from "../Widget/AccountState";

export default function Account() {

  const {lang} = useContext(LangauageContext);
  const {auth} = useContext(AuthContext);
  const {ChartChangeLength,chartData, overAll} = useContext(ChartContext);
  let state = (auth !== null ? JSON.parse(sessionStorage.getItem('user')).approved_balance : 0);
  const [balance,setBalance] = useState(state);  
  

  const handelBlance = ()=>{
    instanceOfAxios.get('/wallet/balance',{
      headers:{
        "Accept-Language":lang,
        Authorization: `Bearer ${auth?.token}`
      }
    }).then((response)=>{
      setBalance(response.data.data.balance)

    }).catch((error)=>{
      console.log(error);
    })
  }

  useEffect(()=>{
    if (auth === null) {
      window.location.replace('/');
    }

    handelBlance();
  });
  return (
    <>
    {auth?.status === 'pending'? <AccountState/> : null}
      <section>
        <h1>{accountLocalization[lang].overview}</h1>
        <div className="container-fluid">
          <div className="row align-items-stretch">
            <div className="col-lg-6">
              <WalletState
                title={widgetLocalization[lang].approvedCommission}
                balance={balance}
                icon={faWallet}
                canUpdate={true}
                updateHandler={handelBlance}
              />
            </div>

            {/* <div className="col-lg-3">
              <WalletState
                title={widgetLocalization[lang].pendingCommission}
                icon={faWallet}
                active={false}
              />
            </div> */}

            <div className="col-lg-6">
              <InvoiceState title={widgetLocalization[lang].invoices} invoiceHint={widgetLocalization[lang].invoiceHint} icon={faFile} />
            </div>
          </div>
        </div>

        <TableState dataSet={overAll} handelChangeLenght={ChartChangeLength}/>
        <ChartState dataSet={chartData}/>
      </section>
    </>
  );
}
